import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Button,
  Row,Col,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

class CustomModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      msg: props.msg,
      status: props.status
    };
    this.hideModal = props.hideModal;
  }

  /*Whenever a class component is updated*/
  componentDidUpdate(prevProps) {
    //  console.log('componentDidUpdate CustomModal', prevProps, this.props);
    if (prevProps.show !== this.props.show) {
      this.setState({show: this.props.show});
    }
    if (prevProps.msg !== this.props.msg) {
      this.setState({msg: this.props.msg});
    }
    if (prevProps.status !== this.props.status) {
      this.setState({status: this.props.status});
    }
   
  }
  isAO = (val) => val instanceof Array || val instanceof Object;

  render() {
    return (

      <>
        <Modal
            size="md"
            bg="dark"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            show={this.state.show}
            onHide={() => this.hideModal() }>
            <Modal.Header className='bg-black'>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="appname text-white">Lotería <span className="color-default-logo">Quisqueya</span></div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className='align-items-center'>
                <Col xs="3" className='text-center'> <FontAwesomeIcon className={(this.state.status === 'ko') ? 'fa-3x text-danger' : 'fa-4x text-success'} icon={faInfoCircle} /></Col> 
                
                <Col xs="9">
                  { this.state.msg && !this.isAO(this.state.msg) && <>  {this.state.msg} </> }
                  {/* es mensaje es un array */}
                  { this.state.msg && this.isAO(this.state.msg) && <> 
                    {this.state.msg[Object.keys(this.state.msg)[0]].map((m, index) => { return <p key={index}>{m}</p> } )}
                  </> }
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='pt-0'>
              <Button onClick={this.hideModal} className="btn-default">Cerrar</Button>
            </Modal.Footer>
          </Modal>
      </>
    )
         
}

}

export default CustomModal;
