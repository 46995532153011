/*!
=========================================================
* Comprar Puntos
=========================================================
*/
import React from 'react';
import {
  Button,
  Card,CardHeader,CardTitle,CardBody,
  InputGroup, FormGroup,Form,Input,Label,
  Container,Row,Col,Table
} from "reactstrap";
import Select from 'react-select';
import Spinner from "../common/Spinner";
import appEnv from "../../variables/env";
import CustomModal from "../common/CustomModal";

class ComprarPuntos extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            idTipoMoneda:1,
            saldo:null,
            tiposMetodosPagosOptions: null,tiposMetodosPagoArray: null,
            tipoMetodoPagoSeleccionado:null,cuentaSeleccionada:null,
            compraPuntosData:null,
            cuentas: null,
            activarBtn:null,
            validationKO:false,
            messageValidation:null,
            msgModal:false,
            showModal:false,
        }
        this._isMounted = false;
  }//end constructor

  /********** Al iniciar el componente **********/
  componentDidMount(){
    this.getCompraPuntos();
    this.getSaldo();
    this.getTiposMetodosPago();
  }//end componentDidMount
  componentWillUnmount() {
     this._isMounted = false;
  }

  getCompraPuntos = () => {
    fetch(appEnv.apiurl+ 'compraPuntos/getCompraPuntos',{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ appEnv.auth('token')
        },
    })
    .then( response => response.json())
    .then(
        // handle the result
        (result) => {
          if (result.error){ window.location.href = '/login'; }
          else{
            this.setState({compraPuntosData:result});
          }
        },
    );
  }

  getSaldo = () => {
    fetch(appEnv.apiurl+ 'monedero/getSaldo',{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ appEnv.auth('token')
        },
    })
    .then( response => response.json())
    .then(
        // handle the result
        (result) => {
          if (result.error){ window.location.href = '/login'; }
          else{
            this.setState({saldo:(result && result.data && result.data.importe) ? result.data.importe : 0});
          }
        },
    );
  }
  getTiposMetodosPago= () => {
    fetch(appEnv.apiurl+'metodoPagos/getMetodoPagos',{
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ appEnv.auth('token')
        }
    }).then(
        (response) => response.json()
      ).then(
        (result) => {
            if (result.status === 'ok'){
              let select = [];
              if (result.data && result.data.length > 0){
                result.data.map((x, index) => {
                  let o = {value: x.id, label: x.nombre };
                  select.push(o);
                })
              }
              this.setState({
                tiposMetodosPagosOptions : select,
                tiposMetodosPagoArray : result.data,
                isLoaded:false
              });
              this.getSaldo();
            }else if (result.error){
              window.location.href = '/login';
            }
            this._isMounted = true;
        }
      );
  }

  onChangeImporte = () => {
    if (Number(this.importeInput.value) <= 0){
      this.setState({cuentas: null, activarBtn:false});
    }
  }
  onChangeTipoMetodoPago = selectedOption => {
    let idTipoMetodoPago = selectedOption.value;
    if (idTipoMetodoPago){
      this.setState({tipoMetodoPagoSeleccionado:idTipoMetodoPago});
      let detalles = this.state.tiposMetodosPagoArray.filter(x => x.id === idTipoMetodoPago);
      if (detalles && detalles.length > 0 && detalles[0].metodosPagos){
        this.setState({
            cuentas: detalles[0].metodosPagos,
            cuentaSeleccionada:detalles[0].metodosPagos[0].id,
            activarBtn:true
        });
      }else{
        this.setState({cuentas: null, cuentaSeleccionada:null, activarBtn:false});
      }
    }else{
      this.setState({
        tipoMetodoPagoSeleccionado:null,
        cuentas: null, cuentaSeleccionada:null,
        activarBtn:false
      });
    }
  }
  onChangeCuenta = (e) => {
      this.setState({
        cuentaSeleccionada:e.target.value
      });
  }

    validationForm = () => {
        const { cuentaSeleccionada } = this.state;
        const importe = this.importeInput.value;

        let isValid = this.validateImporte(importe) && this.validateCuenta(cuentaSeleccionada);
        if (isValid) this.setState({ validationKO: false, messageValidation: '' });

        return isValid;
    }

    validateImporte = (importe) => {debugger
        if (!importe || importe < 10){
            this.setState({validationKO: true, messageValidation: 'El importe debe ser mayor a 10 puntos'});
            return false;
        }
        return true;
    }

    validateCuenta = (cuentaSeleccionada) => {
        if (!cuentaSeleccionada){
            this.setState({validationKO: true, messageValidation: 'Debe seleccionar una cuenta'});
            return false;
        }
        return true;
    }

  comprar = () =>{
    const self = this;
    if (!this.validationForm()){
          return;
    }
    let data = {
      importe: this.importeInput.value,
      idMetodoPago : this.state.cuentaSeleccionada,
      idTipoMoneda: this.state.idTipoMoneda
    }
    fetch(appEnv.apiurl+ 'compraPuntos/insertCompraPuntos',{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ appEnv.auth('token')
        },
        body: JSON.stringify(data)
    })
    .then( response => response.json())
    .then(
        // handle the result
        (result) => {
         self.handleModal(result.status,result.message);
         this.importeInput.value = null;
         this.setState({cuentas: null, activarBtn:false});
         this.getCompraPuntos();
         this.getSaldo();
        },
    );
  }

  handleModal = (status, msg) =>{
      this.setState({
            msgModal:msg,
            showModal: !!(msg),
            statusModal: (status) ? status : '',
      });
  }

  /********** RENDER **********/
  render() {
      const {isLoaded, compraPuntosData, saldo, tiposMetodosPagosOptions, cuentas, cuentaSeleccionada, activarBtn, validationKO, messageValidation, showModal, msgModal, statusModal} = this.state;
      return(
        <>
        <CustomModal show={showModal} hideModal={() => this.setState({showModal : false})} msg={msgModal} status={statusModal}/>
        <Row>
            <Col>
                {
                    validationKO &&
                    <CardHeader className="bg-transparent">
                        <div className="alert alert-default" role="alert">
                            {messageValidation}
                        </div>
                    </CardHeader>
                }
              <Card className="bg-dark">
                <CardHeader className="bg-grey card-header h3 text-light">
                  <Row>
                      <Col>Puntos Actuales</Col>
                      <Col className="text-right"><span>{saldo}</span></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {isLoaded && <Spinner/>}
                  <Form>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label className="text-light">Ingrese la cantidad que desea recargar</Label>
                            <InputGroup className="bg-white">
                                <Input
                                    innerRef={(node) => (this.importeInput = node)}
                                    className="form-control-alternative"
                                    placeholder="100"
                                    type="text"
                                    inputMode="numeric"
                                    defaultValue="100"
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        if (/^\d*$/.test(val)) {
                                            this.onChangeImporte(e);
                                        } else {
                                            e.target.value = val.slice(0, -1);
                                        }
                                    }}
                                    required
                                />
                              <span className="input-group-text border-0">Puntos</span>
                              <Button type="button" color="success" className="rounded-right" onClick={e => this.importeInput.value = Number(this.importeInput.value) + 100}><i className="ni ni-fat-add"/></Button>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Label className="text-light">Seleccione el método de pago</Label>
                            <Select
                             closeMenuOnSelect={false}
                             options={tiposMetodosPagosOptions}
                             onChange = {this.onChangeTipoMetodoPago}
                           />
                          </FormGroup>
                        </Col>
                      </Row>
                        {cuentas &&
                        <Card className="bg-default pt-2 mb-4">
                            <Col lg="12"> <CardTitle className="text-light"> ¿Cómo realizar el pago? <hr className="border-primary mt-1 mb-1"/></CardTitle></Col>
                            <CardBody className="pt-0 text-light">

                                <ul>
                                    <li>1º. Paso elija una de nuestras cuentas</li>
                                </ul>
                                {cuentas.map((x, index) => {
                                    return <Card key={index} className="bg-dark mb-2 p-4">
                                        <div className="custom-control custom-radio mb-3">
                                            <Input
                                                className="custom-control-input"
                                                id={"metodoPago" + x.id}
                                                name="metodoPago"
                                                type="radio"
                                                value={x.id}
                                                defaultChecked={(cuentaSeleccionada === x.id)}
                                                onChange={this.onChangeCuenta}
                                            />
                                            <Label className="custom-control-label" htmlFor={"metodoPago" + x.id}>
                                                <p className="font-weight-bold m-0">{x.nombre}</p>
                                                {x.numero_cuenta && <p className="m-0"> n.º {x.numero_cuenta} </p>}
                                                {x.nombre_beneficiario &&
                                                    <p className="m-0"> A nombre de {x.nombre_beneficiario}</p>}
                                            </Label>
                                        </div>
                                    </Card>
                                })}
                            </CardBody>
                            <Col lg="12" className="text-light">
                                <ul>
                                    <li>2º. Paso realizar el pedido</li>
                                    <li>3º. Paso realizar la transferencia colocando como concepto la referencia indicada en el paso 2</li>
                                    <li>4º. Paso enviar el comprobante de la transferencia al
                                        email <a href="mailto:info@loteriaquisqueya.com">info@loteriaquisqueya.com</a>
                                    </li>
                                </ul>
                            </Col>
                        </Card>
                        }
                      {activarBtn &&
                          <Row>
                        <Col lg="12">
                              <Button type="button" className="w-100" color="success" onClick={this.comprar}> Realizar Pedido </Button>
                        </Col>
                      </Row>
                      }
                  </Form>
                </CardBody>
              </Card>
            </Col>
        </Row>

        <Row>
          <Col>
          {compraPuntosData && compraPuntosData.data && compraPuntosData.data.length > 0 && <>
              <Row className="justify-content-center mt-5">
                <Col xs="12">   
                  <h4 className='text-right'>  <span className="btn-inner--icon"><i className="ni ni-bag-17" /></span> Mis Compras</h4>
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="p-0">
                    
                        <Table className="align-items-center table-dark" responsive>
                          <thead className="thead-dark">
                            <tr>
                            <th scope="col text-light" className="text-center"># Referencia</th>
                              <th scope="col text-light" className="text-center">Fecha de compra</th>
                              <th scope="col text-light" className="text-center">Puntos</th>
                              <th scope="col text-light" className="text-center">Fecha de actualización</th>
                              <th scope="col text-danger" className="text-center">Estado</th>
                            </tr>
                          </thead>
                          <tbody>

                          { compraPuntosData.data.map((x, i) => {
                            return <tr key={i}>
                              
                              <td className="text-center text-success font-weight-bold"> {x.numero_solicitud} </td>
                              <td className="text-center"> {x.created_at} </td>
                              <td className="text-center">{x.importe} </td>
                              <td className="text-center"> <i className="ni ni-info"></i> {x.fecha_ultima_actualizacion}</td>
                              <td className="text-center">
                                <div className={(x.fecha_ultima_actualizacion) ? 'btn btn-outline-primary':'btn btn-outline-danger'}> {x.estado} </div>
                              </td>
                            </tr>

                          })}

                          </tbody>
                      </Table>
                    
                    
                    </CardBody>
                  </Card>
                </Col>
              </Row>
        </>}
          
          </Col>
        </Row>
        </>
      );
  }

}
export default ComprarPuntos;
