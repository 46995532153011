/*!
=========================================================
* FOOTER: CLIENTE NO AUTENTICADO
=========================================================
*/
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import appEnv from "../../../variables/env";

const Login = () => {
    return ( <>
        <footer className = "py-5 mt-5">
          <div className="container-fluid">
             <Row className = "align-items-center justify-content-xl-between">
                <Col xl="12">
                  <Nav className="nav-footer justify-content-center justify-content-xl-end">
                    <NavItem><NavLink className="text-white" href = "/nosotros">Sobre Nosotros </NavLink></NavItem>
                    <NavItem><NavLink className="text-white" href = "/politica-de-privacidad">Políticas </NavLink></NavItem>
                    <NavItem><NavLink className="text-white" href = "/termino-condiciones">FAQS </NavLink> </NavItem>
                  </Nav>
                </Col>
            </Row>
              <div className="copyright text-center text-xl-right text-white"> {appEnv.appname}  ©{ new Date().getFullYear() } { " " }</div>
            </div>
          </footer>
        </>
    );
};

export default Login;
