/*!

=========================================================
*  Nosotros
=========================================================
*/
import React from 'react';
import {
    Row,
    Col, Card, CardBody,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";

const Nosotros = (props) => {
    return (
        <>
            <div id="header-main" className="header bg-gradient-info mt-5">
                <div id="header-body" className="header-body text-center">
                    <Card className="bg-gradient-primary rounded p-3">
                        <div id="header-main" className="header">
                            <h3 className="title-pagina-publicas">Conócenos un poco más</h3>
                        </div>
                    </Card>
                </div>
            </div>

            <Row className="justify-content-center text-center align-items-start p-3">
                <Col md="12">
                    <div className='h2 text-left pb-4 mt-4 mt-lg-0'>
                        <p className="text-white mt-3">
                            <strong>LoteríaQuisqueya.com</strong>, es una página web que se especializa en la venta de la loterías de la República Dominicana. Iniciamos queriendo hacer felices a muchas personas con la venta de loterías y hoy por fin contamos con nuetra web LoteríaQuisqueya.com.
                        </p>
                        <p className="text-white mt-3">
                            <strong>LoteríaQuisqueya.com</strong>, es un sitio independiente sobre información de loterías, y que no tiene ningún tipo de relación con los organismos organizadores de lotería. Toda la información, estadísticas, noticias y cualquier otro dato de este sitio han sido obtenidos de fuentes públicas, intentando mantener el máximo rigor informativo y de veracidad de los datos.
                        </p>
                        <p className="text-white mt-3">
                            <strong>LoteríaQuisqueya.com</strong>, que es, en otras frases, vendemos y comercializamos sorteos de loterías dominicana. Somos una página exclusivamente recreativa, además de informar los resultados instantáneos de todos los sorteos, brindamos informaciones de cada sorteo (como se juega, cómo son los premios, los horarios, probabilidades y más).
                        </p>
                        <p className="text-white mt-3">
                            Si necesitas o quieres más información no dudes en contactarnos vía telefónicas o en nuestro formulario de contacto.
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Nosotros;
