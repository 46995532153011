import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import AppEnv from "../../variables/env";
import {
    Button,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col, Row, NavLink,
} from "reactstrap";
import {Link} from "react-router-dom";

function ForgotPassword() {
    const {handleSubmit} = useForm();
    const [KOmessage, setKOmessage] = useState(null);
    const [token, setToken] = useState(null);
    const [email, setEmail] = useState(null);
    const [OKmessage, setOKmessage] = useState(null);
    const [submittedForm, setSubmittedForm] = useState(null);


    useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        setToken(params.get('token'));
        setEmail(params.get('email'));
    }, []);

    const setErrorForm = (message) => {
        setKOmessage(message);
        setTimeout(() => {
            setKOmessage(null);
        }, 3000);
        setSubmittedForm(null);
    }
    /********** Al enviar el formulario **********/
    const onSubmit = (data, e) => {
        const formDataElements = e.target.elements;
        if (formDataElements.password.value.length < 6) {
            setErrorForm('La contraseña debe tener al menos 6 caracteres');
            return;
        }
        if (formDataElements.password.value !== formDataElements.confirmationPassword.value) {
            setErrorForm('Las contraseñas no coinciden');
            return;
        }
        fetchAction(formDataElements);
    }

    const fetchAction =(formDataElements) => {
        setKOmessage(null);
        setSubmittedForm(1);
        fetch(AppEnv.apiurl + 'auth/resetPassword', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password: formDataElements.password.value,
                confirmationPassword: formDataElements.confirmationPassword.value,
                token: token,
                email: email
            })
        })
            .then((response) => {
                if(response.ok) { return response.json(); }
            })
            .then((result) => handleResponse(result) )
            .catch( error => setErrorForm(error));
    }

    /********** Al responder la API **********/
    const handleResponse = (result) => {
        let isError = (!(result && result.status === 'ok'));
        let message = (result && result.message) ? result.message : 'error encontrado';
        if (isError) {
            setErrorForm(message);
        }else{
            setKOmessage(null);
            setOKmessage(message);
        }
    }
    /********** RENDER **********/
    return (
        <>
            <Col lg="6" className='order-1 order-lg-2 mt-lg-5'>
                <h2 className='h1 text-light'>Nueva contraseña</h2>
            </Col>
            {KOmessage &&
                <CardHeader className="bg-transparent">
                    <div className="alert alert-default" role="alert">
                        {KOmessage}
                    </div>
                </CardHeader>
            }
            {/* formulario */
                !OKmessage &&
            <CardBody className="mt-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <FormGroup className="mb-3 col-6">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    id="password"
                                    placeholder="Nueva Contraseña"
                                    type="password"
                                    name="password"
                                    required={true}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3 col-6">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    id="confirmationPassword"
                                    placeholder="Confirmar Contraseña"
                                    type="password"
                                    name="confirmationPassword"
                                    required={true}
                                />
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="text-center">
                        <Button className="my-4 btn-app" color="primary" type="submit" size='md'>
                            {!KOmessage && (<span>Enviar</span>)}
                            {KOmessage && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                        </Button>
                    </div>
                </form>
            </CardBody>
            }
            {/* mensaje OK onSubmit */
                OKmessage &&
                <CardBody className="px-lg-5 py-lg-5 bg-gradient-primary rounded">
                    <div className="text-center h1 text-light">{OKmessage}</div>
                </CardBody>
            }
            <Row className="mt-3">
                <Col xs="6" className='text-left'>
                    <NavLink
                        className="nav-link-icon nav-link"
                        to="/login"
                        tag={Link}>
                        <span className="nav-link-inner"><i className="ni ni-key-25"></i> Inicia sesión</span>
                    </NavLink>
                </Col>
            </Row>
        </>
    );
}
export default ForgotPassword;
