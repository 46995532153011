/*!
=========================================================
* LOGIN
=========================================================
*/
import React, { useState } from 'react';
import {useLocation, useHistory} from "react-router-dom";
import Auth from '../../middlewares/Auth';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { useForm } from "react-hook-form";
import AppEnv from "../../variables/env.js";

const Login = (props) =>{
  console.log('props login', props.location, document.referrer);

  React.useEffect(() => {
    //document.getElementById("header-action").classList.add("d-none");
    localStorage.clear();
  }, []);


  const pathname = props.pathname || '';
  const navigate = useHistory();
  const search = useLocation().search;
  const apiGetMsg = new URLSearchParams(search).get('msg');

  const {handleSubmit} = useForm();
  const [KOmessage, setKOmessage] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [submittedForm, setSubmittedForm] = useState(null);

  const setErrorForm = (message) => {
    setKOmessage(message);
    setTimeout(() => {
      setKOmessage(null);
    }, 5000);
    setSubmittedForm(null);
  }

  /********** Al enviar el formulario **********/
  const onSubmit = (data, e) => {
    setKOmessage(null);
    setSubmittedForm(1);
    const formDataElements = e.target.elements;

    fetch(AppEnv.apiurl+'auth/login', {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: formDataElements.email.value,
            password: formDataElements.password.value,
            dataCookieCesta:AppEnv.getIdCesta(),
        })
    })
    .then((response) => {
      if(response.ok) { return response.json(); }
    })
    .then((result) => handleResponse(result))
    .catch( error => console.log(error));
  }

  /********** Al responder la API **********/
  const handleResponse = (result) => {
      let isError = (result && result.status === 'ok') ? false : true;
      let message = (result && result.message) ? result.message : 'error encontrado';
      if (isError) {
        setErrorForm(message);
      }else{
        Auth.authenticate(result.data.token,result.data.perfil);
        setAuthenticatedUser(1);
        window.location.href = localStorage.getItem('privatePath') || '/cliente/jugar';
        localStorage.removeItem('privatePath');
      }
  }

/********** RENDER **********/
  return (
    <>
      {!authenticatedUser && <>
        <Row className="justify-content-center text-center align-items-stretch mt-5 ml-0 mr-0">
          <Col lg="6" className='order-1 order-lg-2 mt-lg-5 mb-5'>
            <h2 className='h1 text-light'>Inicia sesión</h2>
            <Card className="bg-dark shadow border-0">
            {KOmessage &&
                <CardHeader className="bg-transparent">
                  <div className="alert alert-default" role="alert">
                      {KOmessage}
                  </div>
                </CardHeader>
            }
            {apiGetMsg &&
                <CardHeader className="bg-transparent">
                  <div className="alert alert-default" role="alert">
                      {apiGetMsg}
                  </div>
                </CardHeader>
            }

            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4"></div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="email"
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="password"
                        placeholder="Contraseña"
                        type="password"
                        autoComplete="new-password"
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Recuérdame</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="my-4 btn-app" color="primary" type="submit" size='md'>
                      {submittedForm && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                      {!submittedForm && (<span>Enviar</span>)}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6" className='text-left'>
                <a
                  className="text-light"
                  href="recuperar-contrasena"
                >
                  <small>Olvide mi Contraseña</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a className="text-light" href="registro">
                  <small>Crea una nueva cuenta</small>
                </a>
              </Col>
            </Row>
          </Col>
      </Row>
      </>}
    </>
  );
};

export default Login;
