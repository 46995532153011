/*!
=========================================================
* PERFIL DE CLIENTE AUTENTICADO
=========================================================
*/
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import ProfileComponent from "components/cliente/Profile";
import appEnv from "../../variables/env.js";
const ProfileView = () => {
  return (
    <div className="h-auto bg-gradient-info pt-5 pb-8">
      {/* Header container */}
      <Container className="align-items-center" fluid>
          <Row><Col><h2>Mi Perfil</h2></Col> </Row>
          <ProfileComponent/>
      </Container>
    </div>
  );
};

export default ProfileView;
