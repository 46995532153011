/*!
=========================================================
* jugadas view
=========================================================
*/
import React from 'react';
import {
  Container,Row,Col,
  Card, CardBody, CardHeader,
  Button
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import JugadasLoteria from "../../components/cliente/JugadasLoteria.js";
const JugadasLoteriaView = (props) =>{

  React.useEffect(() => {
    if (document.getElementById("header-main")) {
      document.getElementById("header-main").classList.add("h-auto");
      document.querySelector("#header-main .separator").remove();
    }
    if (document.getElementById("header-body")) document.getElementById("header-body").classList.add("d-none");


  }, []);

  /********** RENDER **********/
  return (
      <>
      <div className="h-auto bg-gradient-info pt-5 pb-8">
      <Container fluid>
        <Row>
          <Col><h2>Mis Jugadas</h2></Col> 
          <Col className='text-right mb-4'> 
              <Button className="btn-icon btn-sm btn-app" color='danger'  type="button" size="md" onClick={e => window.location.href = '/cliente/jugar'}>
                  <span className="btn-inner--icon">
                    <i className="ni ni-check-bold" />
                  </span>
                  <span className="btn-inner--text">Volver a Jugar</span>
              </Button>
          </Col>
        </Row>
        <Row>
          <Col>
          <Card className="bg-dark">
                <CardHeader className="bg-grey  card-header h3 text-light">
                  <Row>
                    <Col className='text-right text-muted'><small><FontAwesomeIcon icon={faInfoCircle} /> Consulta aquí tus jugadas más recientes. </small></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                     
                  <JugadasLoteria {...props}/>
                
                </CardBody>
            </Card>
          </Col>
        </Row>
            
      </Container>
      </div>

      </>
  );
};

export default JugadasLoteriaView;
