/*!

=========================================================
*  Política de Privancidad
=========================================================
*/
import React from 'react';
import {
    Row,
    Col, Card,
} from "reactstrap";

const PoliticaDePrivacidad = (props) => {
    return (
        <>
            <div id="header-main" className="header bg-gradient-info mt-5">
                <div id="header-body" className="header-body text-center">
                    <Card className="bg-gradient-primary rounded p-3">
                        <div id="header-main" className="header">
                            <h3 className="title-pagina-publicas">Política de Privacidad</h3>
                        </div>
                    </Card>
                </div>
            </div>

            <Row className="justify-content-center text-center align-items-start p-3">
                <Col md="12">
                    <div className='h2 text-left pb-4 mt-4 mt-lg-0'>
                        <p className="text-white mt-3">
                            Nuestra política de privacidad explica cómo recopilamos, almacenamos y utilizamos la información que obtenemos a través de los distintos servicios o páginas disponibles en este sitio web. Es fundamental que comprenda qué información recopilamos y cómo la utilizamos, ya que el acceso a este sitio web implica la aceptación de nuestra política de privacidad.
                        </p>

                        <h4 className="font-weight-600 text-white">Cookies:</h4>

                        <p className="text-white mt-3">
                            El acceso a este sitio web puede requerir la utilización de cookies. Las cookies son pequeños archivos de información que se almacenan en el navegador utilizado por cada usuario, permitiendo que el servidor recuerde cierta información para su uso posterior. Estos datos permiten identificarlo a usted como un usuario específico y guardar sus preferencias personales, así como información técnica, como visitas o páginas específicas que haya visitado. Si no desea recibir cookies o desea ser notificado antes de que se almacenen en su computadora, puede configurar su navegador para tal fin.
                        </p>

                        <p className="text-white"> Las cookies no se aceptan nunca.</p>

                        <p className="text-white"> El navegador pregunta al usuario si se debe aceptar cada cookie.</p>

                        <p className="text-white"> Las cookies se aceptan siempre.</p>

                        <p className="text-white"> El navegador también puede brindar la opción de especificar con más precisión qué cookies se deben aceptar y cuáles no. Por lo general, los usuarios pueden optar por rechazar las cookies de ciertos dominios o de terceros, aceptar cookies como no persistentes (que se eliminan al cerrar el navegador) o permitir que el servidor cree cookies para un dominio diferente. Además, los navegadores también pueden permitir a los usuarios visualizar y eliminar cookies individualmente.</p>

                        <h4 className="font-weight-600 text-white">Terceros:</h4>

                        <p className="text-white mt-3">
                            En ocasiones, podemos compartir información sobre los usuarios de este sitio de manera anónima o agregada con terceros, como anunciantes, patrocinadores o auditores. El objetivo de esta colaboración es mejorar nuestros servicios. Sin embargo, todas estas actividades de procesamiento estarán sujetas a las regulaciones legales vigentes y respetarán plenamente sus derechos en cuanto a la protección de datos.
                        </p>

                        <p className="text-white mt-3">
                            Este sitio web utiliza diversas soluciones para medir el tráfico, las cuales pueden emplear cookies o web beacons para analizar lo que ocurre en nuestras páginas. En la actualidad, utilizamos las siguientes soluciones para la medición del tráfico de este sitio web. Para obtener más información acerca de la política de privacidad de cada una de estas soluciones, puede consultar el siguiente enlace:
                        </p>

                        <h4 className="font-weight-600 text-white">Google (Analytics): <a href="https://analytics.google.com/analytics/web/provision/#/provision" target="_blank">nalytics.google.com</a></h4>

                        <p className="text-white mt-3">
                            Este sitio web puede mostrar publicidad propia, de afiliados o de redes publicitarias. Estos anuncios se exhiben mediante servidores publicitarios que, a su vez, emplean cookies para mostrar contenido publicitario relacionado con los intereses de los usuarios. Cada uno de estos servidores publicitarios tiene su propia política de privacidad, la cual puede ser consultada en sus respectivos sitios web.
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default PoliticaDePrivacidad;
