/*!
=========================================================
* LAYOUT CLIENTE
=========================================================
* rutas que requieren autenticacion
*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import Navbar from "components/common/Navbars/Navbar.js";
import HeaderInfo from "components/cliente/HeaderInfo.js";
import Footer from "components/common/Footers/Footer.js";
import Sidebar from "components/common/Sidebar/Sidebar.js";

import appEnv from "variables/env.js";

import routes from "routes.js";

const Cliente = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const clienteAutenticado = appEnv.auth();
  React.useEffect(() => {
    document.body.classList.add("bg-default");
    document.body.classList.add("cliente");
    return () => {
      document.body.classList.remove("bg-default");
      document.body.classList.remove("cliente");
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainContent &&  mainContent.current) mainContent.current.scrollTop = 0;
    if (!clienteAutenticado) localStorage.setItem('privatePath', location.pathname);
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/cliente") {
        return (
          <Route { ... props}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>

      {clienteAutenticado && (
        <>
          <Sidebar/>
          <div className="main-content h-auto" ref={mainContent}>
            <Navbar  {...props} brandText={getBrandText(props.location.pathname)} />
            <HeaderInfo {...props} />
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/cliente/jugar" />
            </Switch>
            
          </div>
          <Container fluid className="bg-default">
              <Footer />
            </Container>
        </>
      )}
      {!clienteAutenticado && (
        <Redirect from={location.pathname} to="/login" />
      )}
    </>
  );
};

export default Cliente;
