import React from 'react';
import {
    Container,
    Row,
    Col,
    Card, CardBody, CardHeader,
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import CombinacionPremiadas from "../../components/loteria/CombinacionPremiadas.js";
const CombinacionPremiadasView = (props) =>{

    /********** RENDER **********/
    return (
        <>
            <Container fluid className='bg-gradient-info pt-2 pb-8'>
                <Row>
                    <Col>
                        <h2>Combinación Premiada</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="bg-dark">
                            <CardHeader className="bg-grey card-header h3 text-light">
                                <Row>
                                    <Col className='text-right text-muted'>
                                        <small><FontAwesomeIcon icon={faInfoCircle} /> Los premios están sujetos a cambios sin previo aviso. Nos reservamos el derecho de modificar las recompensas según sea necesario, dependiendo de las circunstancias y condiciones que puedan surgir.</small>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className='table-wrapper'>
                                <CombinacionPremiadas {...props}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    );
};

export default CombinacionPremiadasView;
