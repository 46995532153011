/*!
=========================================================
* Cupones digitales
=========================================================
  paso0: lista para elegir,
  paso1: selecciona importe y cantidad,
  añadir a la cesta
*/
import React from 'react';
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import Select from "react-select";
import Spinner from "../common/Spinner";

import Importes from "./CuponesImportes";
import BtnAddCesta from "../cesta/BtnAddCesta";
import appEnv from "../../variables/env";


class Cupones extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            importe: null, cantidad: 1,
            tarifaEuro:[],
            cestaCookieValue : null,
            error : null,
            isLoaded : true,
            data:[],
            data0:[],
            toast: { msg:null, show:false, tclass:null },
            active: false,
            paso: null,
            tipoServicio : null,
            cuponSeleccionado : null,
            metododepago:null,
            pedirBeneficiario:false, beneficiario: {},
            paso0 : false, styles0:null, seleccion0:null,
            toastMsg:null,
            toastShow:null,
            toastClass:null,
            cantidad : null,
            tasaActual: null,
            importeElegido:null,
            datosCesta:null

        };
        this.cuponImgPath = appEnv.apiimgpath + 'servicios/';
        this._isMounted = false;
        // this.paramTipo = this.props.match.params.tipo;
        // this.loggedIn = Auth.getAuth();
        // this.tokenAccess = Auth.getToken();
        // this.elegirCuponDigital = this.elegirCuponDigital.bind(this);
  }//end constructor

  /********** Al iniciar el componente **********/
  componentDidMount(){
    fetch(appEnv.apiurl+'codigosDigitales',{
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(
        (response) => response.json()
      ).then(
        (result) => {
            if (result.status === 'ok'){
              this.setState({data0 : result.data, paso : 0,isLoaded:false});
            }
            this._isMounted = true;
        }
      );
  }//end componentDidMount
  componentWillUnmount() {
     this._isMounted = false;
  }
  /********** Control de paso **********/
  pasoAnterior = () => {
      const paso = Number(this.state.paso);
      this.setState({paso:paso - 1});
  }

  /**************** Al elegir Cupon **********/
  elegirCupon = (event, data, key) => {
      const seleccion = data.cd;
      this.setState({
          datosCesta: {
            producto: seleccion,
            importe: null,
            cantidad: this.state.cantidad,
          },
          styles0 : {
            backgroundImage : 'url('+ this.cuponImgPath + seleccion.logo + ')'
          },
          cuponSeleccionado:seleccion,
          paso: 1
      });

  }

  /**************** Al elegir Importe **********/
  setImporte = (importe) => {
      this.setState({importe:Number(importe)});
      var datosCesta = this.state.datosCesta;
      datosCesta.importe = Number(importe);
      this.setState({ datosCesta: datosCesta });
  }
  /*handleChangeImporte = (event, data, key) => {
      this.setState({
        importeElegido: key,
        importe:data.importe.importe,
        siglasMoneda:data.importe.moneda_origen,
        siglasMonedaDestino:data.importe.monedas_destino,
        idMonedaDestino:data.importe.id_monedas_destino,
        idMonedaOrigen:data.importe.id_moneda_origen,
        cantidadRecarga: data.importe.cantida_recargar,
        tasaActual: data.importe.tasa_actual,
        tipoServicio: data.importe.tipo_servicio
      });
  }*/
  /************ Elegir cantidad **********/
  setCantidad = (cantidad) => {
        this.setState({cantidad:Number(cantidad)});
        var datosCesta = this.state.datosCesta;
        datosCesta.cantidad = Number(cantidad);
        this.setState({ datosCesta: datosCesta });
  }
  /*handleChangeCantidad = (cantidad) => {
      this.setState({ cantidad }, () =>
          this.state.cantidad.value
      );
  };*/
  /***********  Recuperar Data que enviaremos al servidor  ***************/
  setDataCesta = () =>{
      /************** validación previa ********************/

      const data = {
          idProducto: this.state.cuponSeleccionado.id,
          importeProducto: (this.state.tipoServicio !== 6 ) ? this.state.importe :  this.state.importeAPagarRecargaTelefonica,
          siglasMonedaOrigen : this.state.siglasMoneda,
          siglasMonedaDestino : this.state.siglasMonedaDestino,
          idMonedaOrigen : this.state.idMonedaOrigen,
          idMonedaDestino : this.state.idMonedaDestino,
          nombreProducto : this.state.cuponSeleccionado.nombre,
          cantidadProducto : this.state.cantidad.value,
          tipoServicio : this.state.tipoServicio,
          cestaCookie : this.state.cestaCookieValue,
          tasaActual : this.state.tasaActual,
      }
      console.log('setDataCesta', data);
      //this.sendDataCesta(data)
  }


  /********** RENDER **********/
  render() {
      const {isLoaded, data0,paso, cuponSeleccionado, cantidad, importe, importeElegido,datosCesta} = this.state;
      return(
          <>
            {isLoaded && <Spinner/>}
            <Container className="mt--7" fluid>

            {paso === 0 &&
              <Row>
                { data0.map((cd, index) => {
                    return  <Col key={index} id={`paso0-${cd.id}`} lg="3" md="6">
                    <Card className="mb-5 card-cupones">
                      <CardBody>
                        <Row>

                          <Col sm="3" className="logo text-center">
                            {cd.logo && <img src={this.cuponImgPath + cd.logo} alt={cd.nombre}/>}
                          </Col>
                          <Col sm="9" className="text-right col-title p-0">
                            <CardTitle
                              tag="h3"
                              className="mb-0"
                            >
                              <span className="small">Contenido Digital </span>
                              <div className="title">{cd.nombre}</div>

                            </CardTitle>

                          </Col>
                          <Col><span className="mt-3 small d-block text-center">Envío instantáneo Canjeable en España </span></Col>
                        </Row>
                        <p className="mt-3 mb-0 text-center">
                          <button type="button" className="btn btn-default btn-md"
                            data-id-cupones-digitales={cd.id}
                            onClick={(e) => this.elegirCupon(e, {cd}, index)}
                            key={index}> Elegir cupón </button>
                        </p>
                      </CardBody>
                    </Card>

                    </Col>
                })}
                </Row>
              }
              {paso === 1 &&
                  <Row>
                      <Col className="col">
                        <Card className="shadow card-cupones">

                          <CardBody>
                          {cuponSeleccionado &&
                              <Row>
                                <Col lg="6" md="4">
                                    <div className="text-left">
                                        <h3 className="mb-0">
                                          <span className="font-weight-200">Producto elegido</span>&nbsp;
                                          <span className="color-default text-uppercase">{cuponSeleccionado.nombre} </span>
                                        </h3>
                                        <span className="small"><i className="ni ni-check-bold text-success"></i>  Envío inmediato del código por e-mail</span>
                                    </div>
                                    <div className="mt-2">
                                        <img src={this.cuponImgPath + cuponSeleccionado.imagen} alt={cuponSeleccionado.nombre}/>
                                    </div>
                                    <div className="mt-2">
                                          <div className="m-2 text-left mx-auto">
                                              <i className="ni ni-check-bold text-success"></i> <span className="small font-weight-bolder">Se enviará el código al correo electrónico con el cual se realiza la compra </span>
                                          </div>
                                    </div>
                                    {cuponSeleccionado.instrucciones && <div>
                                        <div className="mt-2 small" dangerouslySetInnerHTML={{ __html: cuponSeleccionado.instrucciones }}></div>
                                      </div>}
                                </Col>
                                <Col lg="6" md="8">
                                  {/* IMPORTES */}
                                  <Importes
                                      importes={cuponSeleccionado.importes}
                                      setImporte = {this.setImporte}
                                      setCantidad = {this.setCantidad}
                                  />


                                      <div className="mt-4 pl-0">
                                          {cuponSeleccionado.descripcion && <div>
                                              <p className="mt-3">{cuponSeleccionado.descripcion}</p>
                                          </div>}

                                          <div className="row p-2 mt-5 mb-5">
                                              <div className="col mt-2">
                                                  <button type={"button"} onClick={this.pasoAnterior} className='btn btn-outline-default btn-md'>
                                                      <i className="ni ni-bold-left" aria-hidden="true"></i>&nbsp; Elegir otro cupón
                                                  </button>
                                              </div>
                                              <div className="col mt-2">
                                                <BtnAddCesta
                                                  importe={importe}
                                                  data={datosCesta}
                                                  >
                                                </BtnAddCesta>
                                              </div>
                                              {/*importe &&
                                              <button type={"button"}  onClick={this.setDataCesta} className='btn btn-outline-default btn-md pull-right'>Añadir a la Cesta &nbsp;
                                                <i className="ni ni-basket" aria-hidden="true"></i></button>
                                              </div>*/}
                                          </div>
                                      </div>
                                </Col>
                              </Row>
                              }
                          </CardBody>
                        </Card>
                      </Col>
                  </Row>
              }
              </Container>
          </>
      );
  }//end render

}
export default Cupones;
