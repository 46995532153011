import { isExpired, decodeToken } from "react-jwt";
// const secretKey = process.env.REACT_APP_API_SEED;

const Auth = {
    isAuthenticated : false,
    token : null,

    authenticate(token,perfil) {
        localStorage.setItem('jwt', token);
        if (perfil){
          localStorage.setItem('name', perfil.nombre || '');
          localStorage.setItem('cid', perfil.cid || '');
          localStorage.setItem('sla', perfil.servicio_loteria_activo || '');
          localStorage.setItem('mpd', perfil.metodos_pagos_disponibles || '');
        }
        this.token = token;
        this.isAuthenticated = true;
    },
    signOut() {
        localStorage.removeItem('jwt');
        localStorage.removeItem('cid');localStorage.removeItem('name');
        localStorage.removeItem('sla');
        localStorage.removeItem('mpd');
        // localStorage.removeItem(prefix+'mpapp');
        this.isAuthenticated = false;
    },
    getAuth() {
        this.token = this.getToken();
        if (!this.token){
          this.isAuthenticated = false;
        }else{
          const myDecodedToken = decodeToken(this.token);
          const isMyTokenExpired = isExpired(this.token);
            if (!myDecodedToken || isMyTokenExpired) {
              this.signOut();
              return false;
            }else{
                this.isAuthenticated = true;
            }
        }
      return this.isAuthenticated;
    },
    getToken(){
        return localStorage.getItem('jwt');
    },
    getCid(){
        return (this.getAuth)?localStorage.getItem('cid'):false;
    },
    getNombre() {
        return (this.getAuth)?localStorage.getItem('name'):false;
    },
    getServicioLoteriaActivo(){
        return (this.getAuth)?localStorage.getItem('sla'):false;
    },
    getMetodosPagosDisponibles(){
        if (this.getAuth){
          const mpd = localStorage.getItem('mpd');
          return (mpd) ? mpd.split(',') : [];
        }else{
          return false;
        }
    },
    getMetodosPagosApp(){
      if (this.getAuth){
        const mpapp = localStorage.getItem('mpapp');
        return (mpapp) ? JSON.parse(mpapp) : {};
      }else{
        return false;
      }
    }

};
export default Auth;
