/*!

=========================================================
*  Política de Privancidad
=========================================================
*/
import React from 'react';
import {
    Row,
    Col, Card,
} from "reactstrap";

const TerminoCondiciones = (props) => {
    return (
        <>
            <div id="header-main" className="header bg-gradient-info mt-5">
                <div id="header-body" className="header-body text-center">
                    <Card className="bg-gradient-primary rounded p-3">
                        <div id="header-main" className="header">
                            <h3 className="title-pagina-publicas">Términos y Condiciones</h3>
                        </div>
                    </Card>
                </div>
            </div>

            <Row className="justify-content-center text-center align-items-start p-3">
                <Col md="12">
                    <div className='h2 text-left pb-4 mt-4 mt-lg-0'>
                        <p className="text-white mt-3">
                            <strong>LoteríaQuisqueya.com</strong>, autoriza al usuario, a tener acceso al presente sitio, siempre que acepte en su totalidad los términos, condiciones, advertencias y avisos legales contenidos en el mismo.
                        </p>

                        <p className="text-white mt-3">
                            Este sitio web está siendo medido mediante Google Analytics y utiliza Google AdSense para fines publicitarios. Estas soluciones emplean cookies anónimas y marcas en las páginas para analizar lo que ocurre en nuestro sitio web.
                        </p>

                        <p className="text-white">Asimismo, las cookies se pueden utilizar con el fin de mejorar su experiencia de navegación en nuestro sitio web, sin que éstas proporcionen referencias que permitan deducir datos personales o del disco duro de su computadora. No obstante, puede configurar su computadora para que notifique y rechace la instalación de cookies enviadas por nuestro sitio web sin que ello afecte su acceso a los contenidos del mismo.</p>

                        <h4 className="font-weight-600 text-white">OBJETO:</h4>

                        <p className="text-white mt-3">
                            <strong>LoteríaQuisqueya.com</strong>, es un medio manejado por enter privado, quien en adelante y para los efectos de este documento se denominara EMPRESA PRIVADA.
                        </p>

                        <h4 className="font-weight-600 text-white">CONDICIONES DE USO:</h4>

                        <p className="text-white mt-3">
                            Los resultados de los sorteos de las loterías mostrados en el sitio web Lotodom.com no constituyen información oficial de las respectivas loterías. El sitio web recopila esta información para facilitar a los usuarios la consulta de los premios, pero no se hace responsable de posibles errores en los datos mostrados. La fuente oficial de los resultados de los sorteos son las actas oficiales de cada lotería y sorteo.
                        </p>

                        <p className="text-white mt-3">
                            El usuario reconoce y acepta que este sitio web no puede garantizar la ausencia total de virus u otros elementos que puedan causar daño en su equipo informático. Por lo tanto, el usuario es responsable de tomar todas las medidas necesarias para proteger su equipo, incluyendo la instalación de software antivirus y de seguridad adecuados. En caso de que el usuario sufra algún daño o perjuicio como consecuencia del acceso o uso de este sitio web, Lotodom.com no será responsable por los mismos. El usuario acepta utilizar este sitio web bajo su propio riesgo y responsabilidad.
                        </p>

                        <h4 className="font-weight-600 text-white">Por parte del USUARIO:</h4>

                        <p className="text-white mt-3">
                            Correcto, el derecho a la privacidad es un derecho fundamental y protegido por la ley. Esto significa que el usuario tiene derecho a que se respete su intimidad e integridad personal, y que cualquier tratamiento de sus datos personales se realice de forma legal y en cumplimiento de las leyes y normativas aplicables. Esto incluye la protección de sus datos personales frente a cualquier tipo de uso no autorizado o ilegal por parte de terceros, incluyendo la recopilación, almacenamiento, procesamiento y divulgación de dichos datos.
                        </p>

                        <h4 className="font-weight-600 text-white">PROTECCION DE LA PROPIEDAD INTELECTUAL:</h4>


                        <p className="text-white mt-3">
                            En resumen, queda prohibido cualquier uso, reproducción, distribución o transformación de los elementos del portal web sin autorización expresa y por escrito de Lotodom.com y/o la EMPRESA PROPIETARIA, así como de aquellos que pertenezcan a empresas o personas naturales colaboradoras o contratantes. Cualquier infracción de estos derechos de propiedad intelectual será perseguida por las vías legales correspondientes.
                        </p>



                    </div>
                </Col>
            </Row>
        </>
    );
};

export default TerminoCondiciones;
