/*!
=========================================================
* cupones view
=========================================================
*/
import React from 'react';
import {
  Container,
  Row,
  Col,

  CardBody
} from "reactstrap";
import CuponesComponent from "components/cupones/Cupones.js";
import appEnv from "variables/env.js";

const CuponesView = (props) =>{
  /********** RENDER **********/
  return (
      <>
        {appEnv.auth && <>
            <div className="header bg-gradient-info pb-8 pt-1 pt-md-8">
            <Container>
              <div className="header-body text-center mb-5">
                <Row className="justify-content-center">
                  <Col>
                    <h2 className="h1 display-2 text-white">Cupones Digitales</h2>
                  </Col>
                </Row>
              </div>
            </Container>
            </div>
          </>}
        <div className="clearfix"></div>
        <Container fluid className="bg-gradient-info">
          <CuponesComponent {...props}/>
        </Container>
      </>
  );
};

export default CuponesView;
