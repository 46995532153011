import React from 'react';
import Spinner from "../common/Spinner";
import appEnv from "../../variables/env";

import {
    Row,
    Col, Table
} from "reactstrap";


class CombinacionPremiadas extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            datos:[],
        }
        this._isMounted = false;
    }//end constructor

    /********** Al iniciar el componente **********/
    componentDidMount(){
        fetch(appEnv.apiurl+'loterias/combinaciones-ganadoras',{
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(
            (response) => response.json()
        ).then(
            (result) => {
                if (result.status === 'ok'){
                    this.setState({datos : result.data,isLoaded:false});
                }
                this._isMounted = true;
            }
        );
    }//end componentDidMount
    componentWillUnmount() {
        this._isMounted = false;
    }

    /********** RENDER **********/
    render() {
        const {isLoaded,datos} = this.state;
        return(
            <>
                <Row className="text-center">
                    {isLoaded && <Spinner/>}
                </Row>
                <Row>
                    {datos && datos.map((x, index) => {
                        return <div className="col-md-4 col-sm-4 col-xs-12" key={index}>
                            <div className="winner-results">
                                <div className="winner-inner">
                                    <span className="draw-type-lottery bg-gradient-primary">{x.type_play}</span>
                                    <img className="winner-images" src="images/lottery-balls.png" alt=""/>
                                    <div className="winner-content mt-2">
                                        <h4>{x.description}</h4>
                                    </div>
                                    <div>
                                        <span className="jackpot">Premio: {x.valor}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    })}
                </Row>
            </>
        );
    }//end render

}

export default CombinacionPremiadas;