/*!
=========================================================
* cupones view
=========================================================
*/
import React from 'react';
import {
  Container,
  Row,
  Col,
  CardBody
} from "reactstrap";
import ComprarPuntos from "components/monedero/ComprarPuntos.js";
import appEnv from "variables/env.js";

const ComprarPuntosView = (props) =>{
  /********** RENDER **********/
  return (
      <>
        <div className="main-content-inner h-auto bg-gradient-info  pt-5 pb-8">
        <Container fluid>
          <Row><Col><h2>Comprar Puntos</h2></Col> </Row>
          <ComprarPuntos {...props}/>
        </Container>
        </div>
      </>
  );
};

export default ComprarPuntosView;
