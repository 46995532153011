/*!
=========================================================
* cupones view
=========================================================
*/
import React from 'react';
import {
  Container,
  Row,
  Col,
  CardBody
} from "reactstrap";
import JugarLoteria from "components/loteria/Jugar.js";
import appEnv from "variables/env.js";

const JugarLoteriaView = (props) =>{
  /********** RENDER **********/
  return (
      <>
        <div className="h-auto bg-gradient-info pt-5 pb-8">
        <Container fluid>
          <JugarLoteria {...props}/>
        </Container>
        </div>
      </>
  );
};

export default JugarLoteriaView;
