import React, { Component } from 'react';

class ImportesCupones extends Component{
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            importes: props.importes || [],
            importe: '',
            cantidad:1,
            importeComision: null,
        };
       this.setImporte = props.setImporte.bind(this);
       this.setCantidad = props.setCantidad.bind(this);
    }
    /************ Al seleccionar de la lista de importes **********/
    seleccionarImporteDesdeLista = (event, data, key) => {
        this.setState({
          opcionSeleccionada: key,
          importe:data.importe.importe
        });
        console.log('seleccionarImporteDesdeLista',data.importe.importe);
        this.setImporte(data.importe.importe); //props
    }
    /************ Al seleccionar importe manual **********/
    habilitarImporteManual = (event, data, key) => {
        this.setState({ opcionSeleccionada: 'manual'});
    }

    /************ Al cambiar importe manual **********/
    handleChangeImporteManual = e => {
        // console.log('handleChangeImporteManual',this.cupon);
        let v = Number(e.target.value);
        if (v === 0 && String(v).length === 1) {
            this.setState({importeManual:''});
        }else{
          this.setState({importeManual:v});
        }
        //tomamos los datos del primer item del array de importes y cambiamos el valor
        let d = Object.assign({}, this.cupon.importes[0]);
        d.importeManual = v;
       this.setImporte(d);

    }
    /************ Al cambiar cantidad de cupones a comprar **********/
    handleChangeCantidad = (cantidad) => {
        this.setState({cantidad : cantidad});
        this.setCantidad(cantidad);
    };
    /***********  handle para disminuir cantidad  ***************/
    handleMinus = () => {
        if (parseInt(this.state.cantidad, 10) - 1 >= 1){
            this.setState({cantidad: parseInt(this.state.cantidad) - 1});
            this.setCantidad( parseInt(this.state.cantidad) - 1);
        }
    }
    /***********  handle para aumentar cantidad  ***************/
    handlePlus = () => {
        this.setState({cantidad: parseInt(this.state.cantidad) + 1});
        this.setCantidad( parseInt(this.state.cantidad) + 1);
    };



    render() {
        const {importes,importe,cantidad,opcionSeleccionada} = this.state;
        return(

                    <>
                        <h4>Elige un importe</h4>
                        {/* LISTA DE IMPORTES  */}
                        {importes && importes.length > 0 && <span>
                            { importes.map((importe, index) => {
                                    return <span key={index}
                                                 id={`importe-cupones-digitales-${index}`}
                                                 onClick={(e) => this.seleccionarImporteDesdeLista(e, {importe}, index)}
                                                 className={opcionSeleccionada === index ? 'cursor-pointer btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3 border-active-intercom' : 'btn btn-outline-default btn-sm mr-2 mt-2 pl-3 pr-3 cursor-pointer'}
                                                > {importe.importe_mostrar }
                                    </span>
                            })}

                        </span>
                      }

                        {(Number(importe) > 0) && <>
                        <h4 className="mt-4 mb-0">Indica la cantidad de cupones</h4>
                          <div className="col-md-6 mt-2 pl-0">
                              <div className="input-group mb-3">
                                      <button type="button" onClick={this.handleMinus} className='btn btn-outline-default border-right-none'>-</button>
                                      <input type="number" className="form-control border-color-default"
                                           value={cantidad}
                                           onChange={this.handleChangeCantidad}
                                           min={1}
                                           max={100}
                                           disabled="disabled"
                                      />
                                      <button type="button"  onClick={this.handlePlus} className='btn btn-outline-default border-left-none'>+</button>
                                  </div>
                          </div>
                        </>}
                    </>

        )
    }
}
export default ImportesCupones;
