/*!

=========================================================
*  HOME
=========================================================
*/
import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import Resultados from "../components/loteria/Resultados.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBagShopping, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
// core components

const Home = (props) => {
  return (
      <>
       <div id="header-main" className="header bg-gradient-info py-lg-5 py-lg-3">
            <div id="header-body" className="header-body text-center">
                <Card className="bg-gradient-primary rounded">
                        <CardBody>
                              <Row className="justify-content-center">
                                <Col lg="6" className="section section-header section-category gradient-light-blue">
                                  <div className="info">
                                      <h2 className=" mt-0 text-light"> Juega tus Loterías Favoritas Online</h2>
                                      <p className=''>Puedes jugar en cualquiera de nuestras loterías disponibles, sólo elige tu lotería favorita y prepárate para jugar.</p>
                                      <div id="header-action">
                                        <a title="Loteria Dominicana" href="/cliente/jugar" className="btn btn-primary btn-app btn-lg">Jugar ahora <FontAwesomeIcon icon={faAngleDoubleRight} /></a>
                                      </div>
                                  </div>
                                </Col>
                                <Col lg="6" className='d-none d-lg-block'>
                                    <img src="./images/index-illus.webp"/>
                                </Col>
                              </Row>
                         </CardBody>
                </Card>
            </div>
       </div>
          <Row className="justify-content-center text-center align-items-start">
            <Col md="12"> <div className='h2 text-left pb-4 color-white mt-4 mt-lg-0'>Resultados Recientes</div></Col>
            <Col lg="8" md="12">
              <Resultados {...props}/>
            </Col>
            <Col lg="4" md="12">
              <Card className="bg-primary">
                    <CardBody className='info-box'>
                      <div className='icon-wrapper mx-auto'>
                        <div className='icon bg-success rounded-circle p-4'><FontAwesomeIcon icon={faBagShopping} /> </div>
                      </div>
                      <div className='clear'></div>
                      <h3 className="h1 info-box-title text-light">Comprar Puntos</h3>
                      <div className="info-box-content text-dark font-weight-bold">Compra Puntos y empieza a jugar. </div>
                      <a className="btn btn-success d-block mt-2 " href="/cliente/comprar-puntos">Comprar Puntos </a>
                    </CardBody>
              </Card>      
            </Col>
          </Row>
      </>
  );
};

export default Home;
