/*!
=========================================================
* Comprar Puntos
=========================================================
*/
import React from 'react';
import Spinner from "../common/Spinner";
// reactstrap components
import {
  Row,
  Col,
  Table
} from "reactstrap";
import appEnv from "../../variables/env";

class JugadasLoteria extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            error : null,
            loading : true,
            datos:[],
        }
        this._isMounted = false;
  }//end constructor

  /********** Al iniciar el componente **********/
   componentDidMount(){
    fetch(appEnv.apiurl+'compraLoteria/getCompraLoteria',{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ appEnv.auth('token')
        }
    }).then(
        (response) => response.json()
      ).then(
        (result) => {
            if (result.status === 'ok'){
              /*result.data = result.data.map((d)=>{
                d.numero_pedido = d.numero_pedido.split(',');
                return d;
              });*/
              console.log(result.data);
              this.setState({datos: result.data, loading:false});
            }
            this._isMounted = true;
        }
      );
   }//end componentDidMount
  componentWillUnmount() {
     this._isMounted = false;
  }

  /********** RENDER **********/
  render() {
      const {loading,datos} = this.state;
      return(
        <>
            {loading && <Spinner/>}
            {!loading && 
            <Row>
              <Col>
              { datos && <>
                { datos.map((x, index) => {

                  return <Table key={index}
                    className="align-items-center table-dark"
                    responsive
                  >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col text-light" className="text-center">Nro. de pedido</th>
                      <th scope="col text-light" className="text-center">Fecha</th>
                      <th scope="col text-light" className="text-center">Estado</th>
                      <th scope="col text-light" className="text-center">Total</th>
                     
                    </tr>
                  </thead>
                 
                   <tbody>
                    <tr>
                      <td className="text-center text-success" width={'25%'}>{x.numero_pedido}</td>
                      <td className="text-center" width={'25%'}>  <i className="ni ni-calendar-grid-58" /> {x.fecha}</td>
                      <td className="text-center" width={'25%'}>
                        <div className="btn btn-outline-primary" width={'25%'}> {x.status} </div>
                      </td>
                      <td className="text-center" width={'25%'}>{x.total}</td>
                    </tr>  
                    <tr>
                      <td colSpan={'100%'} className="p-0">
                        <Table className="align-items-center w-100 table-dark table-sm mb-4">
                            <thead>
                              <tr>
                                <th scope="col text-light" className="text-center  text-muted">Loteria</th>
                                <th scope="col text-light" className="text-center text-muted">Hora</th>
                                <th scope="col text-danger" className="text-center text-muted">Importe</th>
                                <th scope="col text-light" className="text-center text-muted">Números Jugados</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                          { x.detalle.map((d, i) => {
                            return (
                                <tr key={i}>
                                    <td className="text-center  text-primary" width={'25%'}>{d.loteria}</td>
                                    <td className="text-center text-primary" width={'25%'}>{d.hora}</td>
                                    <td className="text-center nresult" width={'25%'}>
                                      { d.numero_jugado.split('-').map((n, nn) => {
                                          return (<span key={nn} className="bg-primary rounded-circle">{n}</span>)
                                      })}   
                                    </td>
                                    <td className="text-center text-primary" width={'25%'}> <i className="ni ni-money-coins"></i> {d.importe}</td>
                                </tr>
                            )    
                          })}
                          </tbody>
                        </Table>
                      </td>  
                    </tr>
                    </tbody>
                 
                  </Table>
                })}
              </> }
              </Col>
            </Row>
            }
      </>
      );
  }//end render

}
export default JugadasLoteria;
