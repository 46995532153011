/*!

=========================================================
* Layout
=========================================================
HOME: CLIENTE NO AUTENTICADO
MENU, FOOTER, WELCOME

*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import Navbar from "components/common/Navbars/Navbar.js";
import Sidebar from "components/common/Sidebar/Sidebar.js";
import Footer from "components/common/Footers/Footer.js";

import routes from "routes.js";

const Public = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        return (
          <Route
            {...props}
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Sidebar/>
      <div className="main-content" ref={mainContent}>
        <Navbar />
       
        {/* Page content */}
        <Container fluid className="public-layout">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/index" />
            </Switch>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Public;
