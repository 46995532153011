/*!
=========================================================
* Jugar Loteria
=========================================================
  paso0: lista para elegir,
  paso1: selecciona importe y cantidad,
  añadir a la cesta
*/
import React from 'react';
import Spinner from "../common/Spinner";
import {
  Row,Col,
  FormGroup,
  Label,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Card, CardBody
} from "reactstrap";

import Cesta from "../cesta/Cesta";
// import BtnAddCesta from "../cesta/BtnAddCesta";
import appEnv from "../../variables/env";

/* Select */
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
const animatedComponents = makeAnimated();



class Jugar extends React.Component {
  
  constructor(props){
        super(props);
        this.state = {
          loading : true,
          error : null,
          msgModal:false,
          loterias:[],
          selectLoterias:[],
          valueSelectLoterias: null,
          tipoJugadas:[],
          selectTipoJugada:null,
          valueSelectTipoJugada:null,
          numeros:[],
          cantidadNumeros:0,
          valueNumeros:[],
          loadingNumeros:null,
          importe:'',
          activarBtnAdd:null,
          showModal:false,
          datosCesta:null,
          reloadCesta: null
        };
        this._isMounted = false;
  }//end constructor

  selectRefTipoJugada = null;

  /********** Al iniciar el componente **********/
  componentDidMount(){
    this.getTiposJugada();
  }//end componentDidMount

  componentWillUnmount() {
     this._isMounted = false;
  }
  callApi = (options, fnNext) =>{
    const params = {
      method: (options.method) ? options.method : 'post',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ appEnv.auth('token')
      },
    };
    if (params.method === 'post'){
      params.body = (options.body) ? JSON.stringify(options.body) : ''
    }
    fetch(appEnv.apiurl+options.endpoind,params).then(
      (response) => response.json()
    ).then(
      (result) => {
          fnNext(result);
      }
    );
  }//callApi

  /********** *get data **********/
  getTiposJugada = () =>{
    const self = this;
    this.callApi({
      endpoind:'loterias/getTipoJugadas',
      method:'get'
    }, function(result){
          if (result.status === 'ok'){
            //parse select
            let selectTipoJugada = [];
            if (result.data && result.data.length > 0){
              result.data.map((x, index) => {
                let o = {value: x.id, label: x.descripcion};
                if (!x.activo || x.disabled) o.disabled = true;
                selectTipoJugada.push(o);
              });
            }
            //1 default
            self.setState({
              selectTipoJugada: selectTipoJugada,
              tipoJugadas: result.data,
              cantidadNumeros: (result.data.length > 0) ? result.data[0].cantidad_numero : 1,
              valueSelectTipoJugada: selectTipoJugada[0],
              loading:false
            });
            self.getLoterias();
            self._isMounted = true;
          }else if (result.error){
            window.location.href = '/login';
          }
      }
    );
  }//getTiposJugada

  //@param tipo de jugada
  getLoterias = () =>{
    const self = this;
    if ( this.state.valueSelectTipoJugada){
      this.callApi({
        endpoind:'loterias/getLoterias',
        body:{ id_tipo_jugada_loteria: this.state.valueSelectTipoJugada.value} 
      }, function(result){
        if (result.status === 'ok'){
          //parse select
          let selectLoterias = [];
          if (result.data && result.data.length > 0){
            result.data.map((x, index) => {
              let o = {value: x.id, label: x.nombre + '\n' + x.descripcion+' \n Cierra a las ' +  x.hora_cierre };
              if (!x.activo || x.disabled) o.isdisabled = true;
              selectLoterias.push(o);
            });
          }
          self.setState({
            selectLoterias : selectLoterias, 
            loterias : result.data,
          });
        }
       //self.selectRefLoteria.select.focus();
       
      });
    }
  }//end getLoterias

  //@param id loterias separados por coma
  getNumerosLoterias = (loteriasSeleccionadas) =>{
    const self = this;
    const pluck = (arr, key) => arr.map(i => i[key]);
    if (loteriasSeleccionadas && loteriasSeleccionadas.length > 0){
      const idsLoteria = pluck(loteriasSeleccionadas, 'value');
      this.callApi({
        endpoind:'loterias/getNumeros',
        body:{ id_loteria: idsLoteria.join()} 
      }, function(result){
        if (result.status === 'ok' && result.data && result.data.length > 0){
            self.setState({
              numeros: result.data,
              loadingNumeros:false
            });
        }
      });
    }
    
  }//end getNumerosLoterias

  getSaldo = (fnNext) =>{
    this.callApi({
      endpoind:'monedero/getSaldo',
    }, function(result){
          fnNext((result && result.data && result.data.importe) ? result.data.importe : 0);
    });  
  }

  /**************** Al elegir Valores **********/
  onChangeTipoJugada = selectedOption => {
    const tipoJugada = selectedOption.value;   
    //buscamos datos de tipo de jugada seleccionada
    const tipoJugadaSeleccionada =  this.state.tipoJugadas.filter(function (x) { return Number(x.id) === Number(tipoJugada)})[0];
    this.setState({
      valueSelectLoterias:null,
      valueSelectTipoJugada: selectedOption,
      cantidadNumeros: Number(tipoJugadaSeleccionada.cantidad_numero),
      numeros:[],
      valueNumeros:[],
      importe:''
    });
    //volvemos a obtener a las loterias por tipo de jugada
    this.getLoterias(); 
    // document.getElementById('selectLoterias').focus();
  };

  onChangeLoteria = selectedOption => {
    if (!selectedOption || selectedOption.length === 0){
      this.setState({numeros:[],valueNumeros:[]
      });
    }
    this.setState({
      valueSelectLoterias:selectedOption,
      importe:''
    });
    //obtenemos los numeros que se pueden jugar
    this.getNumerosLoterias(selectedOption); 
  }

  onClickNumero = (index,value) => {
    let valueNumeros = this.state.valueNumeros;
    if (valueNumeros.length === 0){
      //rellenamos posiciones para evitar error
      for (let i = 0; i < this.state.cantidadNumeros; i++) {valueNumeros.push(''); }
    }
    valueNumeros[index] = value;
    this.setState({
      valueNumeros:valueNumeros
    });
    //si se ha seleccionado el total de numeros
    const numerosValidos = this.validarNumerosJugados();
    if (numerosValidos){ this.validarJugada(); }
}

  cambiarNumero = (index) => {
    let valueNumeros = this.state.valueNumeros;
    valueNumeros[index] = '';
    this.setState({valueNumeros:valueNumeros, importe : ''});
  }

  /*-------------------- *VALIDACIONES *CONDICIONES */
  validarJugada = (fnNext) => {
      console.log('validarJugada jugada');
      if (this.state.valueSelectLoterias)  {  //si hay loterias seleccionadas
        //condiciones a validar 
        const numerosValidos = this.validarNumerosJugados();
        console.log('numerosValidos', numerosValidos);
        const errorImporteSuperiorAlPermitido = this.validarImporteJugada();
        console.log('errorImporteSuperiorAlPermitido', errorImporteSuperiorAlPermitido);
        let datosCesta = [];
        if (numerosValidos && !errorImporteSuperiorAlPermitido){
            console.log('importe', Number(this.state.importe));
            //guardamos datos en datosCesta para enviar array de los productos elegidos
            this.state.valueSelectLoterias.map((x, index) => {
              datosCesta.push({
                id_tipo_servicio: 1,
                id_loteria : x.value,
                id_tipo_jugada: this.state.valueSelectTipoJugada.value,
                cantidad:1,
                descripcion: x.label,
                importe_producto: Number(this.state.importe),
                numeros_jugado:this.state.valueNumeros.join()
              });
            });
            console.log('datosCesta',datosCesta);
        }else{
          this.handleModal('ko','Jugada No Válida');
          //this.setState({msgModal: 'Jugada No Válida', msgStatus:});
          setTimeout(() => this.setState({msgModal: false}), 3000);
        }
        this.setState({
          datosCesta: datosCesta
        });
        if (fnNext) fnNext(datosCesta);
      } else{
        this.setState({
          datosCesta: null
        });
        if (fnNext) fnNext(null);
      }   
  }

  handleModal = (status, msg) =>{
    this.setState({
      msgModal:msg,
      showModal: !!(msg),
      statusModal: (status) ? status : '',
    });
  }//handleModal

  validarNumerosJugados = () => {
    let v = false;
    //validamos cantidad de numeros requeridos segun tipo de jugada
    let cantidadNumeros = this.state.cantidadNumeros;
    Array.from(Array(cantidadNumeros)).map((n, i) =>{ v = (this.state.valueNumeros[i]) ? true : false; });
    return v;
  }

  validarImporteJugada = () => {
    let error = false;
    if (Number(this.state.importe) > 0) {
      //*de los numeros jugados obtenemos el importe máximo
      let arrLimitesImporteNumeros = [];
      this.state.valueNumeros.map((x) => {
        let objNumero = this.state.numeros.filter((n) => String(n.numero) === String(x))[0];
        if (objNumero)  arrLimitesImporteNumeros.push(objNumero.importe_disponible);
      });
      //si jugada.importe <= al menor de los importes en arrLimitesImporteNumeros;
      const importeMaxPermitido = (arrLimitesImporteNumeros.length > 0) ? Math.min(...arrLimitesImporteNumeros) : false;    
      if (importeMaxPermitido && Number(this.state.importe) > importeMaxPermitido){
        error = 'Importe Máximo permitido '+ importeMaxPermitido;
      }
    } 
    return error;
  }
  /**------------------------------------------------------------------**/
  resetJugada = () =>{
    //default tipo de jugada 1
    this.setState({
      valueSelectTipoJugada: this.state.selectTipoJugada[0],
      numeros:[],cantidadNumeros: 1, valueNumeros:[],
      selectLoterias:[],valueSelectLoterias:null,
      importe: '',
      datosCesta:null
    });
    this.getLoterias();
  }

  setMsgModal = (message) =>{
    this.setState({msgModal: message, loading:false});
    setTimeout(() => this.setState({msgModal: null}), 3000);
  }
 
  /********** RENDER **********/
  render() {
      const {loading, 
        tipoJugadas, selectTipoJugada,valueSelectTipoJugada,
        selectLoterias, valueSelectLoterias,
        numeros, cantidadNumeros, valueNumeros, loadingNumeros,
        importe, datosCesta} = this.state;
      return(
          <>
            {loading && <Spinner/>}
            {!loading && <>
                <Form>
                  <Card className="bg-default">
                    <CardBody>
                      {/* Tipos de jugadas */}
                      {tipoJugadas.length > 0 &&
                          <Row className='mb-4'>
                            <Col>
                              <FormGroup>
                                <Label className="text-light">Elige el Tipo de Jugada</Label>
                                <Select
                                    id="selectTipoJugada"
                                    onChange={this.onChangeTipoJugada}
                                    closeMenuOnSelect={true}
                                    // components={animatedComponents}
                                    placeholder='Seleccione un tipo de jugada'
                                    options={selectTipoJugada}
                                    defaultValue={valueSelectTipoJugada}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                      }
                       {/* loterias */}
                       {selectLoterias.length > 0 &&
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="text-light">Selecciona las Loterías</Label>
                            <Select 
                              id="selectLoterias"
                              onChange={this.onChangeLoteria}
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              placeholder='Seleccione una lotería'
                              isMulti
                              options={selectLoterias}
                              isOptionDisabled={(option) => option.isdisabled} // disable an option
                              value = {valueSelectLoterias}
                              autoFocus = {true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                        }

                      {/* Números para jugar */}
                      {loadingNumeros &&  <Row><Spinner/></Row> }
                      {!loadingNumeros && numeros.length > 0 && <Row>
                          {Array.from(Array(cantidadNumeros)).map((c, i) =>
                            <Col key={i} className="col-lg-4 col-12 text-center mb-4">
                              {/* si ya ha jugado el número lo mostramos */}
                              {valueNumeros.length > 0 && valueNumeros[i] && <>
                                <FormGroup>
                                  <Label className="text-light">{(i+1) + 'º Jugada'}</Label>
                                </FormGroup>
                                <div className='nresult mb-4'>
                                  <span className='bg-app text-light rounded-circle mx-auto'>{valueNumeros[i]} </span>
                                </div>
                                <button type="button"  
                                   onClick={(e) => this.cambiarNumero(i)}
                                  className='btn btn-sm btn-default'> Cambiar Número <FontAwesomeIcon icon={faXmark} /> </button>
                              </>}
                              {/* mostramos números */}
                              {!valueNumeros[i] && <> 
                                <FormGroup>
                                  <Label className="text-light">{'Elige tu ' + (i+1) + 'º Número'}</Label>
                                </FormGroup>
                                <div className='nresult text-left'>
                                {
                                  numeros.map((n, ii) => {return <span key={ii} 
                                    className={(Number(n.enabled) === 1)?'pointer bg-success rounded-circle':'disabled bg-default rounded-circle'}
                                    onClick={(e) => this.onClickNumero(i,n.numero)}
                                    >{n.numero}</span>
                                  })
                                }
                                </div>
                              </> } 
                            </Col>
                          )}
                      </Row> }

                      {/* Importe */}
                      {valueNumeros.length === Number(cantidadNumeros) &&
                          <Row>
                              <Col>
                                <FormGroup className='mb-0'>
                                  <Label className="text-light">Monto a Jugar</Label>
                                  <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-money-coins" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      id="importe"
                                      className="form-control-alternative"
                                      type="text"
                                      onKeyUp={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={event => {
                                          this.setState({importe:event.target.value});
                                      }}
                                      value={importe}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                          </Row>
                      }
                     
                      </CardBody>
                    </Card>
                 </Form>

                {/* CESTA */}
                <Cesta servicio="Compra Loteria" 
                    btnClassName="btn-app btn-lg mt-4" btnText="Agregar jugada"
                    importe={importe}
                    data={datosCesta}
                    beforeInsert={this.validarJugada}
                    callbackInsert={this.resetJugada}
                />
            </>}  
        </>
      );
  }//end render

}
export default Jugar;
