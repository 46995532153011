/*!
=========================================================
* NAVBAR USUARIO NO AUNTENTICADO
=========================================================
*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import appEnv from "../../../variables/env.js";


const PublicNavbar = (props) => {
const logoPath = "";

  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container fluid className="mx-4 bg-grey rounded-lg">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt={appEnv.appname}
                      src='/images/brand/logo.webp'
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* NAV CLIENTE NO AUTENTICADO */
            !appEnv.auth() && (
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/registro"
                  tag={Link}>
                  <span className="nav-link-inner--text btn-registre"><i className="ni ni-circle-08" /> Regístrate</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link-icon" to="/login" tag={Link}>
                  <span className="nav-link-inner--text btn-login"><i className="ni ni-key-25" /> Inicia Sesión</span>
                </NavLink>
              </NavItem>
            </Nav>
            )}
        </Container>

      </Navbar>
    </>
  );
};

export default PublicNavbar;
