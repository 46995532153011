import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import AppEnv from "../../variables/env";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col, NavLink,
} from "reactstrap";
import {Link} from "react-router-dom";

function ForgotPassword() {
  const {handleSubmit} = useForm();
  const [KOmessage, setKOmessage] = useState(null);
  const [OKmessage, setOKmessage] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [submittedForm, setSubmittedForm] = useState(null);

  const handleInputFocus = event => {
      setKOmessage(null);
  }
  const setErrorForm = (message) => {
    setKOmessage(message);
    setTimeout(() => {
      setKOmessage(null);
    }, 5000);
    setSubmittedForm(null);
  }
  /********** Al enviar el formulario **********/
  const onSubmit = (data, e) => {
    const formDataElements = e.target.elements;
    setKOmessage(null);
    setSubmittedForm(1);
    fetch(AppEnv.apiurl + 'auth/recuperarContrasena', {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: formDataElements.email.value
        })
    })
    .then((response) => {
      if(response.ok) { return response.json(); }
    })
    .then((result) => handleResponse(result) )
    .catch( error => setErrorForm(error));

  }
  /********** Al responder la API **********/
  const handleResponse = (result) => {
      let isError = (!(result && result.status === 'ok'));
      let message = (result && result.message) ? result.message : 'error encontrado';
      if (isError) {
        setErrorForm(message);
      }else{
        setKOmessage(null);
        setOKmessage(message);
      }
  }
  /********** RENDER **********/
  return (
    <>
    {!authenticatedUser && <>
       <Row className="rounded justify-content-center text-center align-items-stretch mt-4 pt-5 pb-5 bg-gradient-primary">
         <Col lg="6" className='order-2 order-lg-1 mt-5 mb-5'>
          <div className="illus-area mt-5">
                  <img alt="LotoDominicana" src="./images/signin-illus.webp" />
                </div>
         </Col>
          <Col lg="6" className='order-1 order-lg-2 mt-lg-5 mb-5'>
            <h2 className='h1 text-light'>Recupera tu contraseña</h2>
          <Card className="bg-dark shadow border-0">
          {/* mensaje de error */
          KOmessage &&
              <CardHeader className="bg-transparent">
                <div className="alert alert-default" role="alert">
                    {KOmessage}
                </div>
              </CardHeader>
          }
          {/* formulario */
          !OKmessage &&
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <div>
                      <span>Por favor, proporciona la dirección de correo electrónico asociada a tu cuenta y te enviaremos un enlace de recuperación de contraseña.</span>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input id="email"
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        onFocus={handleInputFocus}
                        required
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button className="my-4 btn-app" color="primary" type="submit" size='md'>
                      {submittedForm && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                      {!submittedForm && (<span>Enviar</span>)}
                    </Button>
                  </div>
                </form>
            </CardBody>
          }
          {/* mensaje OK onSubmit */
            OKmessage &&
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center h4">{OKmessage}</div>
              </CardBody>
          }
          </Card>
          <Row className="mt-3">
            <Col xs="6" className='text-left'>
                <NavLink
                    className="nav-link-icon nav-link"
                    to="/login"
                    tag={Link}>
                    <span className="nav-link-inner text-light"><i className="ni ni-key-25"></i> Inicia sesión</span>
                </NavLink>
            </Col>
            <Col className="text-right" xs="6">
                <NavLink
                    className="nav-link-icon nav-link"
                    to="/registro"
                    tag={Link}>
                    <span className="nav-link-inner text-light"><i className="ni ni-circle-08"></i> Crea una nueva cuenta</span>
                </NavLink>
            </Col>
          </Row>
        </Col>
      </Row>  
      </>}
    </>
  );
};
export default ForgotPassword;
