/*!

=========================================================
* REGISTRO
=========================================================
*/

import React, { useState } from 'react';
import Auth from '../../middlewares/Auth';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useForm } from "react-hook-form";
import AppEnv from "../../variables/env.js";
import CountrySelect from "../common/selectCountry";

const Register = () => {
  const {handleSubmit} = useForm();
  const [KOmessage, setKOmessage] = useState(null);
  const [OKmessage, setOKmessage] = useState(null);
  const [submittedForm, setSubmittedForm] = useState(null);
  const [invalidFields, setInvalidFields] = useState({ password: false, password_confirmation: false })

  React.useEffect(() => {
    //document.getElementById("header-action").classList.add("d-none");
  }, []);

  const validForm = (formData) => {debugger;
      let invalids = {...invalidFields}
    /* validaciones */
    let msgError = '';
    for(var prop in formData) {
        let o = {};
        if(!formData[prop]) {
          msgError = 'Todos los datos solicitados son obligatorios.';
       }
    }
    if (formData.password !== formData.password_confirmation){
       msgError = 'La contraseña y la confirmación de contraseña deben ser iguales.';
        invalids.password = true;
        invalids.password_confirmation = true;
        setInvalidFields(invalids);
    }else if (formData.password.length < 6){
       msgError = 'La contraseña y la confirmación de contraseña deben ser iguales.';
    }

    if ( msgError !== '' ) { setErrorForm(msgError);
        return false;
    }else {
        return true;
    }
  }
  const setErrorForm = (message) => {
    setKOmessage(message);
    setTimeout(() => {
      setKOmessage(null);
    }, 5000);
    setSubmittedForm(null);
  }

  /********** Al enviar el formulario **********/
  const onSubmit = (data, e) => {
    setKOmessage(null);
    setSubmittedForm(1);
    const formDataElements = e.target.elements;
    const formData = {
      email: formDataElements.email.value,
      nombre: formDataElements.nombre.value,
      apellidos: formDataElements.apellidos.value,
      telefono: formDataElements.telefono.value,
      id_pais: formDataElements.country.value,
      password: formDataElements.password.value,
      password_confirmation: formDataElements.confirmpassword.value,
    }
    const formDataValidar = {
          email: formDataElements.email.value,
          telefono: formDataElements.telefono.value,
          password: formDataElements.password.value,
          password_confirmation: formDataElements.confirmpassword.value
      }
    if (validForm(formDataValidar)){
      sendFormData(formData);
    }
 }

 const sendFormData = (formData) =>{
        fetch(AppEnv.apiurl + 'auth/register', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then((response) => {
          if(response.ok) { return response.json(); }
        })
        .then((result) => handleResponse(result) )
        .catch( error => setErrorForm(error));
      }

  /********** Al responder la API **********/
  const handleResponse = (result) => {
      let isError = (!(result && result.status === 'ok'));
      let message = (result && result.message) ? result.message : 'error encontrado';
      if (isError) {
        setErrorForm(message);
      }else{
        setKOmessage(null);
        setOKmessage(message);
      }
  }

  /********** RENDER **********/
  return (
    <>
      <Row className="justify-content-center text-center align-items-stretch">
          <Col lg="6" className='order-1 order-lg-2 mt-lg-5 mb-5'>
            <h2 className='h1 text-white'>Crear Cuenta</h2>
        <Card className="bg-dark shadow border-0">
        {/* mensaje de error */
          KOmessage &&
            <CardHeader className="bg-transparent">
              <div className="alert alert-default" role="alert">
                  {KOmessage}
              </div>
            </CardHeader>
        }
        {/* formulario */
          !OKmessage &&
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-white mb-4">
              Regístrate para poder disfrutar de todos nuestros servicios.
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-email-83" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input id="email"
                               placeholder="Correo Electrónico"
                               type="email"
                               autoComplete="new-email"
                               required="required"
                        />
                    </InputGroup>
                    <span className="text-sm-08 text-muted float-right">* Campo obligatorio</span>
                </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input id="nombre" placeholder="Nombre" type="text"/>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input id="apellidos" placeholder="Apellidos" type="text"/>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fa fa-phone"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input id="telefono" placeholder="Teléfono" type="tel" required="required"/>
                    </InputGroup>
                  <span className="text-sm-08 text-muted float-right">* Campo obligatorio</span>
              </FormGroup>
              <CountrySelect/>
              <FormGroup className="mb-1">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input id="password"
                    type="password"
                    autoComplete="new-password"
                    className={invalidFields.password ? 'error' : ''}
                    placeholder={invalidFields.password ? 'Error: check this field' : 'Contraseña'}
                  />
                  <Input id="confirmpassword"
                    type="password"
                    autoComplete="new-password"
                    className={invalidFields.password_confirmation ? 'input-error' : ''}
                    placeholder={invalidFields.password_confirmation ? 'Debe confirmar la contraseña' : 'Confirma Contraseña'}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic"><small>La contraseña debe tener al menos 6 caracteres</small></div>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      required="required"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        He leído y acepto las {""}
                        <a href="/politica-de-privacidad">
                          Políticas de privacidad
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button className="my-4 btn-registre" type="submit" size='md'>
                {submittedForm && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
                {!submittedForm && (<span>  Crear nueva cuenta</span>)}
                </Button>
              </div>
            </form>
          </CardBody>
        }
        {/* mensaje OK onSubmit */
          OKmessage &&
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center h4">{OKmessage}</div>
            </CardBody>
        }
        </Card>
        <Row className="mt-3">
          <Col className="text-right">
            <a className="text-light" href="login">
              <small>Si ya tienes cuenta, inicia sesión</small>
            </a>
          </Col>
        </Row>
      </Col>
      </Row>
    </>
  );
};

export default Register;
