/*!
=========================================================
* cupones view
=========================================================
*/
import React from 'react';
import {
  Container,
  Row,
  Col,
  Card, CardBody, CardHeader,
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import Resultados from "../../components/loteria/Resultados.js";
const ResultadosLoteriaView = (props) =>{

  React.useEffect(() => {
    if (document.getElementById("header-main")) {
      document.getElementById("header-main").classList.add("h-auto");
      document.querySelector("#header-main .separator").remove();
      
    }
    if (document.getElementById("header-body")) document.getElementById("header-body").classList.add("d-none");
    if (document.querySelector(".main-content")) document.querySelector(".main-content").classList.add("bg-dark");


  }, []);

  /********** RENDER **********/
  return (
      <>
      <Container fluid className='bg-gradient-info pt-2 pb-8'>
        <Row><Col><h2>Resultados</h2></Col> </Row>
        <Row>
          <Col>
          <Card className="bg-dark">
                <CardHeader className="bg-grey card-header h3 text-light">
                  <Row>
                    <Col className='text-right text-muted'><small><FontAwesomeIcon icon={faInfoCircle} /> Consulta aquí los resultados de los sorteos más recientes, para ver resultados anteriores puedes visitar el sitio web de cada lotería. </small></Col>
                  </Row>
                </CardHeader>
                <CardBody className='table-wrapper'>
                     
                  <Resultados {...props}/>
                
                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      </>
  );
};

export default ResultadosLoteriaView;
