import Home from "views/Home.js";
import Registro from "views/auth/Registro.js";
import Login from "views/auth/Login.js";
import ForgotPassword from "./views/auth/ForgotPassword.js";
import ResetPassword from "./views/auth/ResetPassword.js";
import Nosotros from "views/publico/Nosotros.js";
import PoliticaDePrivacidad from "views/publico/PoliticaDePrivacidad.js";
import TerminoCondiciones from "views/publico/TerminoCondiciones.js";

import ResultadosLoteria from "views/loteria/Resultados.js";
import CombinacionPremiadas from "views/loteria/CombinacionPremiadas.js";


/* rutas privadas */
import JugarLoteria from "views/loteria/Jugar.js";
import Cupones from "views/cupones/Cupones.js";
import ComprarPuntos from "views/monedero/ComprarPuntos.js";
import Movimientos from "views/monedero/Movimientos.js";

import JugadasLoteria from "views/cliente/JugadasLoteria.js";
import Profile from "views/cliente/Profile.js";

/*****************************************/
var routes = [

  {
    path: "/index",
    name: "Index",
    component: Home,
    layout: "/public",
  },
    /*----- auth -----*/
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/public",
  },
  {
    path: "/registro",
    name: "Registro",
    component: Registro,
    layout: "/public",
  },
  {
    path: "/recuperar-contrasena",
    name: "Recuperar Contraseña",
    component: ForgotPassword,
    layout: "/public",
  },
  {
    path: "/reset-contrasena",
    name: "Restaurar Contraseña",
    component: ResetPassword,
    layout: "/public",
  },

  /*----- cliente -----*/

  {
    path: "/perfil",
    name: "Mis datos",
    component: Profile,
    layout: "/cliente",
  },
  {
    path: "/jugadas",
    name: "Mis Jugadas",
    component: JugadasLoteria,
    layout: "/cliente",
  },
  /*----- loteria -----*/
  {
    path: "/jugar",
    name: "Jugar Loteria",
    component: JugarLoteria,
    layout: "/cliente",
  },
  {
    path: "/resultados",
    name: "Resultados Loteria",
    component: ResultadosLoteria,
    layout: "/public",
  },
  {
    path: "/resultados",
    name: "Resultados Loteria",
    component: ResultadosLoteria,
    layout: "/cliente",
  },
  /*----- monedero -----*/
  {
    path: "/comprar-puntos",
    name: "Monedero",
    icon: "ni ni-planet text-blue",
    component: ComprarPuntos,
    layout: "/cliente",
  },
  {
    path: "/cartera",
    name: "Monedero",
    icon: "ni ni-planet text-blue",
    component: Movimientos,
    layout: "/cliente",
  },

  {
    path: "/cupones-digitales",
    name: "Cupones Digitales",
    icon: "ni ni-planet text-blue",
    component: Cupones,
    layout: "/cliente",
  },
  /*----- Publicos -----*/
  {
    path: "/nosotros",
    name: "publico",
    icon: "ni ni-planet text-blue",
    component: Nosotros,
    layout: "/public",
  },
  {
    path: "/politica-de-privacidad",
    name: "publico",
    icon: "ni ni-planet text-blue",
    component: PoliticaDePrivacidad,
    layout: "/public",
  },
  {
    path: "/termino-condiciones",
    name: "publico",
    icon: "ni ni-planet text-blue",
    component: TerminoCondiciones,
    layout: "/public",
  },
  {
    path: "/award-winning-combination",
    name: "Combinacion Premiadas",
    icon: "ni ni-planet text-blue",
    component: CombinacionPremiadas,
    layout: "/public",
  },

];
export default routes;
