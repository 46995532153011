/*!
=========================================================
* PERFIL DE CLIENTE AUTENTICADO
=========================================================
*/
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Alert,
  Row,
  Col,Table
} from "reactstrap";
import CustomModal from "../common/CustomModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import Spinner from "../common/Spinner";
import appEnv from "../../variables/env";


class Cesta extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            loading: true,
            cesta: null,
            servicio : props.servicio,
            servicioLoteria : 'Compra Loteria',
            btnClassName: props.btnClassName || ' btn-outline-default btn-md ',
            btnText: props.btnText || 'Comprar',
            data:props.data || null,
            importe:  props.importe || 0,
            message : null,
            activarBtn: null,
            msgModal:false,
            showModal:false,
            statusModal:'',
        };
        this.callbackInsert = props.callbackInsert.bind(this);
        this.beforeInsert = props.beforeInsert.bind(this);
  }//end constructor

/********** Al iniciar el componente **********/
componentDidMount(){
  this.getProductosCesta();
}//end componentDidMount

componentWillUnmount() {
    this._isMounted = false;
}//componentWillUnmount

componentWillReceiveProps(nextProps) {
  if (nextProps.importe !== this.props.importe) {
    this.setState({importe: nextProps.importe});
   // console.log('en cesta', nextProps.importe);
  }
  if (nextProps.data !== this.props.data) {
    this.setState({ data:nextProps.data});
  }

}//componentWillReceiveProps

/********** *send *get data a la api **********/
//obtener data cesta
getProductosCesta = () => {
  const self = this;
  this.setState({loading : true});
  this.callApi({
    endpoind:'cesta/getProductoCesta',
    body:{ id_cesta: appEnv.getIdCesta() }
  }, function(result){
    let data = [], message = null;
    //console.log('result', result);
    if (result && result.data && result.data.length > 0){
      //separamos por tipo de servicio
      const r = result.data.reduce((acc, item) => {
          if (!acc[item.tipo_servicio]) {
            acc[item.tipo_servicio] = [];
          }
          acc[item.tipo_servicio].push(item);
          return acc;
        }, {});
      
      for (let i = 0; i < Object.keys(r).length; i++) {
        if ( !self.state.servicio || r[Object.keys(r)[i]][0].tipo_servicio.toLowerCase() === self.state.servicio.toLowerCase()){
            let obj = {
              tipo_servicio : r[Object.keys(r)[i]][0].tipo_servicio,
              data:[]
            }
            r[Object.keys(r)[i]].map((d, index) => {
              if (d.numeros_jugado && d.numeros_jugado.length > 0 ) {  d.numeros_jugado = d.numeros_jugado.split(','); }
              obj.data.push(d);
              return d;
            });
            data.push(obj);
          }
      }
    }else{
      message = result.message;
    }
    //  console.log('data cesta',data);
    self.setState({cesta : data, loading:false, message:message});
    self._isMounted = true;
  });
}//getDataCesta

//al hacer clic en el botón comprar
controlInsert = () =>{
    const self = this;
    this.beforeInsert(function(datos){
        self.setState({ data:datos});
        if (datos && datos.length > 0){
            self.insertProductoCesta(datos);
        }
    });
}
insertProductoCesta = (datos) => {
  const self = this;
  this.setState({importe:0,loading : true});
  this.callApi({
    endpoind:'cesta/insert',
    body:{ productos: (datos) ? datos : this.state.data }
  }, function(result){
      appEnv.setIdCesta(result.data['idCesta']);
      appEnv.setCountCesta(result.data['count']);
      //funciones props
      if (self.callbackInsert) self.callbackInsert(result);
      self.handleModal(result.status,result.message);
      self.setState({importe:0,loading : false});
      self.getProductosCesta();
  });
}
//al hacer clic en el boton delete
borrarProductCesta = (e) =>{
  e.preventDefault();
  const button = e.target;
  const d = button.dataset.id || null;
  this.setState({loading : true});
  if (d){
    const self = this;
    this.callApi({
      endpoind:'cesta/eliminarProducto',
      body:{ id_cesta_detalle: d }
    }, function(result){
      self.getProductosCesta();
    });
  }else{
    self.getProductosCesta();
  }
}//borrarProductCesta


//al hacer clic en el boton realizar pedido
realizarPedido = () =>{
  const self = this;
  let path = null;
  switch(this.state.servicio) {
    case this.state.servicioLoteria:
        path = 'compraLoteria/insertCompraLoteria';
      break;
  } 
  if (path){
      self.setState({loading: true});
    this.callApi({
      endpoind:path,
    }, function(result){
        if (result.status !== 'ko'){
            self.setState({cesta : []});
            result.data.saldoActual
            let eleHeaderSaldo = document.getElementById("headerSaldo");
            if (eleHeaderSaldo) eleHeaderSaldo.innerHTML = (result.data.saldoActual) ? Number(result.data.saldoActual) : 0
        }
        self.setState({loading: false});
        self.handleModal(result.status,result.message);
    });
  }
} //realizarPedido

/********** otras funciones **********/
handleModal = (status, msg) =>{
  this.setState({
      msgModal:msg,
      showModal: !!(msg),
      statusModal: (status) ? status : '',
  });
}//handleModal
callApi = (options, fnNext) =>{
    fetch(appEnv.apiurl+options.endpoind,{
      method: (options.method) ? options.method : 'post',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ appEnv.auth('token')
      },
      body: (options.body) ? JSON.stringify(options.body) : ''
  }).then(
      (response) => response.json()
    ).then(
      (result) => {
          fnNext(result);
      },
      // Handle error
      (error) => {
        this.setState({
            error
        })
    },
    );
}//callApi

  /********** RENDER **********/
  render() {
      const {btnClassName,btnText, importe, servicioLoteria, cesta,
         message, loading, msgModal, showModal, statusModal } = this.state;
      return(
        <>
          <CustomModal show={showModal} hideModal={() => this.setState({showModal : false})} msg={msgModal} status={statusModal}/>
          {importe > 0 && <div className='text-center'>
            <button type="button"  onClick={this.controlInsert} className={'btn ' + btnClassName}> {btnText}</button>
          </div>} 
          <Card className="mt-5 mb-5 bg-dark">
            <CardBody>
              <Row>
                <Col>
                    {loading && <Spinner/>}
                    {!loading && <>
                      {cesta && cesta.length > 0 && <>
                            <Row className="justify-content-center">
                              <Col xs="12">   
                                <Card className="bg-secondary shadow border-0">

                                  <CardBody className="p-0">
                                      { cesta.map((c, index) => {
                                        return <Col key={index} className="p-0">
                                          {c.data[0].tipo_servicio === servicioLoteria &&
                                          <Table
                                              className="align-items-center table-dark"
                                              responsive>
                                              <thead className="thead-dark">
                                                <tr>
                                                  <th scope="col text-light" className="text-center">Compra Lotería</th>
                                                  <th scope="col text-light" className="text-center">Números Jugados</th>
                                                  <th scope="col text-light" className="text-center">Monto</th>
                                                  <th scope="col text-danger" className="text-center">Borrar</th>
                                                </tr>
                                              </thead>
                                              <tbody>

                                              { c.data.map((x, i) => {
                                                return <tr key={i} id={'tr-' + x.id_cesta_detalle} >
                                                  <td className="text-center">
                                                    {x.loteria}
                                                    <p className="small m-0 text-primary">{x.tipo_jugada}</p>
                                                  </td>
                                                  <td className="text-center nresult">{
                                                    x.numeros_jugado.map((n, ii) => {return <span key={ii} className="bg-success rounded-circle">{n}</span>})
                                                  }</td>
                                                  <td className="text-center"> <i className="ni ni-money-coins"></i> {x.importe_producto}</td>
                                                  <td className="text-center">
                                                    <Button className="btn-danger" data-id={x.id_cesta_detalle} onClick={this.borrarProductCesta}><FontAwesomeIcon className='noevent' icon={faTrash} /></Button>
                                                  </td>
                                                </tr>
                                              })}

                                              </tbody>
                                          </Table>
                                        }
                                        </Col>}
                                      )}
                                  
                                  </CardBody>
                                </Card>
                              </Col>

                              <Col xs="12">
                                <Row className="justify-content-center mt-4">
                                  <Col xs="6">
                                    <Button type="button" className="w-100 btn-app btn-lg" onClick={this.realizarPedido}> Realizar Pedido </Button>
                                  </Col>  
                                </Row>  
                              </Col>  
                            </Row>   

                      </>}
                      {!cesta || cesta.length === 0 && 
                        <Row>
                          <Col>{message}</Col>
                        </Row>
                      }
                 
                    </> } 
                  </Col>
              </Row>
           </CardBody>
         </Card>
        </>
      );
  }//end render

}



export default Cesta;
