import React, { useState, useEffect } from 'react';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import appEnv from "../../variables/env";


const CountrySelect = () => {
    const [countries, setCountries] = useState([]);

    const fetchCountries = async () => {
        try {
            const response = await fetch(appEnv.apiurl+'Paises/getPaises');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCountries(data.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Debemos usar useEffect para evitar solicitudes AJAX excesivas
    useEffect(() => {
        fetchCountries();
    }, []); // Pasamos un array vacío para simular componentDidMount
    return (
        <FormGroup>
            <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="fa fa-globe"/>
                    </InputGroupText>
                </InputGroupAddon>
                <Input type="select" name="country" id="country" required>
                    <option value="">Seleccionar país...</option>
                    {countries.map(country => (
                        <option key={country.id} value={country.id}>{country.nombre}</option>
                    ))}
                </Input>
            </InputGroup>
            <span className="text-sm-08 text-muted float-right">* Campo obligatorio</span>
        </FormGroup>
    );
};

export default CountrySelect;