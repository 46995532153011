/*!
=========================================================
* Comprar Puntos
=========================================================
*/
import React from 'react';
import Spinner from "../common/Spinner";
// reactstrap components
import {
  Row,
  Col,
  Table
} from "reactstrap";
import appEnv from "../../variables/env";

class ResultadosLoteria extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            datos:[],
        }
        this._isMounted = false;
  }//end constructor

  /********** Al iniciar el componente **********/
   componentDidMount(){
    fetch(appEnv.apiurl+'resultados/index',{
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    }).then(
        (response) => response.json()
      ).then(
        (result) => {
            if (result.status === 'ok'){

              result.data = result.data.map((d)=>{
                d.resultados = d.resultados.split(',');
                return d;
              });

              this.setState({datos : result.data,isLoaded:false});
            }
            this._isMounted = true;
        }
      );
   }//end componentDidMount
  componentWillUnmount() {
     this._isMounted = false;
  }

  /********** RENDER **********/
  render() {
      const {isLoaded,datos} = this.state;
      return(
        <>
            <Row>
              {isLoaded && <Spinner/>}
            </Row>
            <Row>
              <Col>
              {datos &&
              <div className="overflow-auto" style={{'height': '800px'}}>
                <Table className="align-items-center table-dark" responsive>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col text-light" className="text-center">Loteria</th>
                      <th scope="col text-light" className="text-center">Fecha</th>
                      <th scope="col text-light" className="text-center">Resultados</th>
                    </tr>
                  </thead>
                  <tbody>

                  { datos.map((x, index) => {
                    return <tr key={index}>
                      <td className="text-center">
                        {x.nombre}
                        <br/><small>{x.hora_cierre}</small>
                      </td>
                      <td className="text-center text-primary">  <i className="ni ni-calendar-grid-58" /> {x.created_at}</td>
                      <td className="text-center nresult">{
                        x.resultados.map((n, i) => {return <span key={i} className="bg-success rounded-circle">{n}</span>})
                      }
                      </td>
                    </tr>
                  })}
                  </tbody>
              </Table>
              </div>
              }
              </Col>
            </Row>
      </>
      );
  }//end render

}
export default ResultadosLoteria;
