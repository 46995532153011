import React from 'react';
import appEnv from "../../variables/env";

class BtnAddCesta extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          btnClassName: props.btnClassName || ' btn-outline-default btn-md ',
          btnText: props.btnText || 'Comprar',
          data:props.data || null,
          importe:  props.importe || 0,
      };
      this.tokenAccess = appEnv.getToken();
      this.callbackInsert = props.callbackInsert.bind(this);
      this.setMsgModal = props.setMsgModal.bind(this);
  }
  /* Cada vez que se actualizan los props */
  componentDidUpdate(prevProps) {
    if (prevProps.importe !== this.props.importe && Number(this.props.importe) > 0) {
      this.setState({
        importe: this.props.importe,
        data:this.props.data
      });
    }
  }
  
  /* al hacer clic en el botón comprar */
  /***********  Enviamos Data al servidor  ***************/
  sendDataCesta = () => {
      fetch(appEnv.apiurl + 'cesta/insert',{
          method: 'post',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ appEnv.auth('token')
          },
          body: JSON.stringify({
              productos:this.state.data
          })
      })
          .then( response => response.json())
          .then(
              // handle the result
              (result) => {
                  appEnv.setIdCesta(result.data['idCesta']);
                  appEnv.setCountCesta(result.data['count']);
                  if (this.callbackInsert) this.callbackInsert(result);
                  if (this.setMsgModal) this.setMsgModal(result.message);
                  this.setState({importe:0});
              },
              // Handle error
              (error) => {
                  this.setState({
                      error
                  })
              },
          );

  }

    /* RENDER botón añadir a la cesta*/
    render() {
        const {btnClassName,btnText, importe} = this.state;

        return(
                <>
                  {importe > 0 && 
                    <button type="button"  onClick={this.sendDataCesta} className={'btn ' + btnClassName}> {btnText}</button>
                  }
                
                </>
        );
    }
}

export default BtnAddCesta;
