/*!
=========================================================
* PERFIL DE CLIENTE AUTENTICADO
=========================================================
*/
import React from 'react';
import {
  Button,
  Card,CardHeader,CardBody,
  FormGroup,Form,Input,Label,
  Alert,Row,Col,
} from "reactstrap";
import Spinner from "../common/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare,faKey} from '@fortawesome/free-solid-svg-icons';
import appEnv from "variables/env.js";

class Profile extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            datos: null,
            isLoaded : true,
            edit: false,
            isHovered: false,
            userAlert:false
        };
        this.handleHover = this.handleHover.bind(this);
  }//end constructor

  /********** Al iniciar el componente **********/
  componentDidMount(){
    const self = this;
    this.callApi({
      endpoind:'client/sumary',
    }, function(result){
      if (result.data && result.data.length > 0){
        self.setState({datos : result.data[0], isLoaded:false});
      }else if (result.error){
        window.location.href = '/login';
      }
      self._isMounted = true;
    });  
    
  }
  componentWillUnmount() {
     this._isMounted = false;
  }
  handleHover(){
    this.setState(prevState => ({
        isHovered: !prevState.isHovered
    }));
}
  toggleEdit = () =>{
    const currentValue = this.state.edit;
    if (currentValue) this.setState({edit:false});
    else this.setState({edit:true});
  }

  /* submit form */
  handleSubmitDatosPersonales = (e) =>{
    e.preventDefault();
    const self = this;
    const dataEditada = {
      nombre: this.nombreInputValue.value,
      apellido: this.apellidosInputValue.value,
      direccion: this.direccionInputValue.value,
      poblacion:this.poblacionInputValue.value,
      ciudad: this.ciudadInputValue.value,
      codigo_postal: this.cpInputValue.value,
      id_pais: this.state.datos.id_pais
    }
    //enviar datos
    this.callApi({
      endpoind:'client/actualizarDatos',
      body:dataEditada
    }, function(result){
      if (result.status && result.status === 'ok'){
        self.setState({edit: false, userAlert: result.message});
        setTimeout(() => self.setState({userAlert: false}), 3000);

      }else if (result.error){
        window.location.href = '/login';
      }
    });  
  }
  handleSubmitCambiarContrasena = (e) =>{
    e.preventDefault();
    const self = this;
    const dataEditada = {
      email : this.state.datos.email,
      password: this.pass1InputValue.value,
      password_confirmation : this.pass2InputValue.value,
    }
    //enviar datos
    this.callApi({
      endpoind:'client/updatePassword',
      body:dataEditada
    }, function(result){
      if (!result.error){
        if (result.status === 'ko') { self.pass1InputValue.focus(); }
        self.setState({userAlert: result.message});
        setTimeout(() => self.setState({userAlert: false}), 7000);
      }else{
        window.location.href = '/login';
      }
    });
  }

  
  callApi = (options, fnNext) =>{
    fetch(appEnv.apiurl+options.endpoind,{
      method: (options.method) ? options.method : 'post',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ appEnv.auth('token')
      },
      body: (options.body) ? JSON.stringify(options.body) : ''
  }).then(
      (response) => response.json()
    ).then(
      (result) => {
          fnNext(result);
      }
    );
  }

  isAO = (val) => val instanceof Array || val instanceof Object;

  /********** RENDER **********/
  render() {
      const {isLoaded, datos, edit, userAlert} = this.state;
      const btnClass = this.state.isHovered ? "text-light" : "text-muted";
      return(
        <>
        {userAlert && !this.isAO(userAlert) && <Alert color="primary">{userAlert}</Alert> }
        {userAlert && this.isAO(userAlert) && <Alert color="primary">
           { userAlert[Object.keys(userAlert)[0]].map((m, index) => { return <div key={index}>{m}</div>} )}
        </Alert> }
         
        {/* datos personales */}
        <Row>

            <Col>
              <Card className="bg-dark">

                <CardHeader className={'bg-grey card-header h3 pointer ' + btnClass} onClick={e => this.toggleEdit()} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}> 
                  <FontAwesomeIcon className="text-success" icon={faPenToSquare} /> Editar datos personales
                </CardHeader>
                <CardBody>
                  {isLoaded && <Spinner/>}
                  {datos &&
                    <Form onSubmit={this.handleSubmitDatosPersonales}>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label className="text-light small">Nombre</Label>
                              <Input innerRef={(node) => this.nombreInputValue = node}
                                className="form-control-alternative"
                                defaultValue={datos.nombre}
                                id="input-nombre"
                                placeholder="Nombre"
                                type="text"
                                disabled = {(!edit)? "disabled" : ""}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label className="text-light small">Apellidos</Label>
                              <Input innerRef={(node) => this.apellidosInputValue = node}
                                className="form-control-alternative"
                                defaultValue={datos.apellidos}
                                id="input-apellidos"
                                placeholder="Apellidos"
                                type="text"
                                disabled = {(!edit)? "disabled" : ""}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label className="text-light small">Usuario</Label>
                              <Input
                                  className="form-control-alternative"
                                  defaultValue={datos.user}
                                  id="input-user"
                                  placeholder="User"
                                  type="text"
                                  required="required"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label className="text-light small">Correo Electrónico</Label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={datos.email}
                                disabled
                                type="email"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            {/*<FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Last name
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Jesse"
                                id="input-last-name"
                                placeholder="Last name"
                                type="text"
                              />
                            </FormGroup> */}
                          </Col>
                        </Row>
                        {/* DIRECCION */}
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <Label className="text-light small">Dirección</Label>
                              <Input innerRef={(node) => this.direccionInputValue = node}
                                className="form-control-alternative"
                                defaultValue={datos.direccion}
                                disabled = {(!edit)? "disabled" : ""}
                                type="text"
                              />
                            </FormGroup>
                          </Col>    
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <Label className="text-light small">Población</Label>
                              <Input innerRef={(node) => this.poblacionInputValue = node}
                                className="form-control-alternative"
                                defaultValue={datos.poblacion}
                                disabled = {(!edit)? "disabled" : ""}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <Label className="text-light small">Ciudad</Label>
                              <Input innerRef={(node) => this.ciudadInputValue = node}
                                className="form-control-alternative"
                                defaultValue={datos.ciudad}
                                disabled = {(!edit)? "disabled" : ""}
                                type="text"
                              />
                            </FormGroup>
                          </Col> 
                          <Col lg="4">
                            <FormGroup>
                              <Label className="text-light small">Código postal</Label>
                              <Input innerRef={(node) => this.cpInputValue = node}
                                className="form-control-alternative"
                                defaultValue={datos.codigo_postal}
                                disabled = {(!edit)? "disabled" : ""}
                                type="text"
                              />
                            </FormGroup>
                          </Col>    
                        </Row>

                        {edit &&
                        <Row>
                          <Col className="text-right">
                                <Button type="submit" color="primary"> Guardar </Button>
                          </Col>
                        </Row>
                        }
                    </Form>
                  }
                </CardBody>
              </Card>
            </Col>
        </Row>
        {/* cambiar contraseña */}
        <Row className="mt-4">
            {/* columna datos personales */}
            <Col>
              <Card className="bg-dark">

                <CardHeader className="bg-grey card-header h3 text-muted"> <FontAwesomeIcon className="text-success" icon={faKey} /> Cambiar Contraseña</CardHeader>
                <CardBody>
                  {isLoaded && <Spinner/>}
                  {datos &&
                    <Form onSubmit={this.handleSubmitCambiarContrasena}>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="text-light small">Nueva Contraseña</Label>
                              <Input innerRef={(node) => this.pass1InputValue = node}
                                className="form-control-alternative"
                                id="input-password"
                                type="password"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label className="text-light small">Confirma nueva contraseña</Label>
                              <Input innerRef={(node) => this.pass2InputValue = node}
                                className="form-control-alternative"
                                id="input-password2"
                                type="password"
                                required
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="text-right">
                                <Button type="submit" color="primary"> Cambiar Contraseña </Button>
                          </Col>
                        </Row>
                    </Form>
                  }
                </CardBody>
              </Card>
            </Col>
        </Row>
        </>
      );
  }//end render

}



export default Profile;
