/*!
=========================================================
* cupones view
=========================================================
*/
import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,CardHeader,CardBody,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import Movimientos from "components/monedero/Movimientos.js";


const MovimientosView = (props) =>{
  /********** RENDER **********/
  return (
    <>
    <div className="h-auto bg-gradient-info pt-5 pb-8">
    <Container fluid>
      <Row><Col><h2>Mi cuenta</h2></Col> </Row>
      <Row>
        <Col>
        <Card className="bg-dark">
              <CardHeader className="bg-grey card-header h3 text-light">
                <Row>
                    <Col className='text-right text-muted'><small><FontAwesomeIcon icon={faInfoCircle} /> Consulta aquí los últimos movimientos de tu cuenta. </small></Col>
                </Row>
              </CardHeader>
              <CardBody className='table-wrapper'>
                   
              <Movimientos {...props}/>
              
              </CardBody>
          </Card>
        </Col>
      </Row>
          
    </Container>
    </div>

    </>
     
  );
};

export default MovimientosView;
