/*!
=========================================================
* NAVBAR menu superior Cliente autenticado
=========================================================
*/
import { Link } from "react-router-dom";
import {Routes, Route, useHistory} from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRepeat} from '@fortawesome/free-solid-svg-icons';
import appEnv from "../../variables/env.js";

const ClienteNavbar = (props) => { console.log('ClienteNavbar',props);
  const assetsPath = "../../../assets/";
  const history = useHistory();
  const logout = (e) =>{
      e.preventDefault();
      appEnv.logout();
      window.location.href = '/';
  }
  const showHeaderSaldo = () =>{
    fetch(appEnv.apiurl+ 'monedero/getSaldo',{
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ appEnv.auth('token')
        },
    })
    .then( response => response.json())
    .then(
        // handle the result
        (result) => {
          if (result.error){ window.location.href = '/login'; }
          else{
            let eleHeaderSaldo = document.getElementById("headerSaldo");
            if (eleHeaderSaldo) eleHeaderSaldo.innerHTML = (result && result.data && result.data.importe) ? result.data.importe : 0
          }
        },
    );
  }

  showHeaderSaldo();

  return (
    <>
        <Container fluid className="bg-grey p-2" style={props.customStyle}>
            <Row>
              {/* <Col lg="6" className="d-none d-md-block"></Col> */}
              <Col lg="12" className="text-md-right text-center headerInfo">
                <Button className="btn-icon btn-3" color="success" type="button" size="md" onClick={() => showHeaderSaldo()} >
                  <span id="headerSaldo" className="btn-inner--text text-white"></span>
                  <span className="btn-inner--text "> <FontAwesomeIcon icon={faRepeat} /></span>
                </Button>
                <Button className="btn-icon btn-3" color="primary" type="button" size="md" onClick={e => window.location.href = '/cliente/comprar-puntos' }>
                  <span className="btn-inner--icon">
                    <i className="ni ni-bag-17" />
                  </span>
                  <span className="btn-inner--text">Comprar puntos</span>
                </Button>
                <Button className="btn-icon btn-3" color="warning" type="button" size="md" onClick={e => window.location.href = '/cliente/jugadas'}>
                  <span className="btn-inner--icon">
                    <i className="ni ni-check-bold" />
                  </span>
                  <span className="btn-inner--text">Jugadas</span>
                </Button>
              </Col>
            </Row>
        </Container>
    </>
  );
};

export default ClienteNavbar;
