/*!
=========================================================
* Comprar Puntos
=========================================================
*/
import React from 'react';
import Spinner from "../common/Spinner";
// reactstrap components
import {
  Container,Row,Col,
  Badge,Table,Button
} from "reactstrap";
import appEnv from "../../variables/env";

class Movimientos extends React.Component {
  constructor(props){
        super(props);
        this.state = {
            error : null,
            loading : true,
            data: null,
        }
        this._isMounted = false;
  }//end constructor

  /********** Al iniciar el componente **********/
   componentDidMount(){
     fetch(appEnv.apiurl+'monedero/getMovimientos',{
         method: 'post',
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             'Authorization': 'Bearer '+ appEnv.auth('token')
         },
         body: JSON.stringify({
           id_cliente: appEnv.clienteId()
         })
     }).then(
         (response) => response.json()
       ).then(
         (result) => {
             if (result.status === 'ok'){
               this.setState({
                 data: result.data,
                 loading:false
               });
             }else if (result.error){
               window.location.href = '/login';
             }
             this._isMounted = true;
         }
       );
   }//end componentDidMount
  componentWillUnmount() {
     this._isMounted = false;
  }


  /********** RENDER **********/
  render() {
      const {loading, data} = this.state;
      return(
          <>
              {loading && <Spinner/>}
              <Row>
                <Col>
                {data &&
                <Table
                    className="align-items-center table-dark"
                    responsive
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col text-light" className="text-center">Tipo</th>
                        <th scope="col text-light">Fecha</th>
                        <th scope="col text-light">Hora</th>
                        <th scope="col text-light">Importe</th>
                        <th scope="col text-light" className="text-center">Estado</th>
                        <th scope="col text-light" />
                      </tr>
                    </thead>
                    <tbody>

                    { data.map((x, index) => {
                      return <tr key={index}>
                        <td className="text-center">
                          {Number(x.importe) < 0 && <Badge color="danger"><i className="ni ni-fat-delete"></i>{x.tipo_servicio}</Badge> }
                          {Number(x.importe) >= 0 && <Badge color="success"><i className="ni ni-fat-add"></i>{x.tipo_servicio}</Badge> }
                        </td>
                        <td>{x.created_at}</td>
                        <td>{x.hours}</td>
                        <td>
                          {Number(x.importe) < 0 && <span className="text-danger font-weight-bold"><i className="ni ni-fat-delete"></i>{Math.abs(x.importe)}</span> }
                          {Number(x.importe) >= 0 && <span className="text-success font-weight-bold"><i className="ni ni-fat-add"></i>{Math.abs(x.importe)}</span> }
                        </td>
                        <td className="text-center">
                          <Button color="white" outline type="button">{x.status}</Button>
                        </td>
                      </tr>

                    })}

                    </tbody>
                </Table>
                }
                </Col>
              </Row>

        </>
      );
  }//end render

}
export default Movimientos;
