/*!

=========================================================
* HOME
*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/custom-styles.css";

import ClienteLayout from "layouts/Cliente.js";
import PublicLayout from "layouts/Public.js";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/cliente" render={(props) => (
        <ClienteLayout {...props} />
        )}
      />
      <Route path="/" render={(props) => (
        <PublicLayout {...props} />
        )}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
