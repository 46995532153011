/*!
=========================================================
* SIDEBAR Cliente autenticado
=========================================================
*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import appEnv from "../../../variables/env.js";
var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => { 
    return window.location.pathname.indexOf(routeName) > -1 ? "active bg-gradient-primary" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active">
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  //Logout
  const logout = (e) =>{
      e.preventDefault();
      appEnv.logout();
      window.location.href = '/';
  }

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const clienteAutenticado = appEnv.auth();

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-dark bg-grey "
      expand="md"
      id="sidenav-main">
      <Container fluid>
        {/* Brand */}
        <NavbarBrand to="/index" tag={Link}>
          {<img
            alt={appEnv.appname}
            src='/images/brand/logo.webp'
          />}
        </NavbarBrand>
        {/* Toggler */}
        <button
            className="navbar-toggler ml-auto"
            type="button"
            onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {!clienteAutenticado && <>
          <Nav className="align-items-center d-md-none">
            <NavItem>
              <NavLink
                className="nav-link-icon small text-light"
                to="/registro"
                tag={Link}>
                <i className="ni ni-circle-08" />
                <span className="nav-link-inner--text">Regístrate</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link-icon small text-light"
                to="/login" tag={Link}>
                <i className="ni ni-key-25" />
                <span className="nav-link-inner--text">Inicia Sesión</span>
              </NavLink>
            </NavItem>
          </Nav>
        </> }
        {/*RESPONSIVE*/}
        {clienteAutenticado && <>
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to="/cliente/jugadas" tag={Link}>
                  <span>  Mis Pedidos</span>
                </DropdownItem>
                <DropdownItem to="#" tag={Link}>
                  <span>Premios</span>
                </DropdownItem>
                <DropdownItem to="/cliente/cartera" tag={Link}>
                  <span>Cartera</span>
                </DropdownItem>
                <DropdownItem to="/cliente/perfil" tag={Link}>
                  <span>Perfil</span>
                </DropdownItem>
                <DropdownItem to="#" onClick={(e) => logout(e)}>
                  <span>Salir</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </> }
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen} className="bg-grey">
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src='/images/brand/logo.webp' />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src='/images/brand/logo.webp' />
                    </a>
                  )}
                </Col>
              ) :   <Col className="collapse-brand" xs="6">
                    {<img
                      alt={appEnv.appname}
                      src='/images/brand/logo.webp'
                    />}
                </Col>
              }
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler text-white"
                  type="button"
                  onClick={toggleCollapse} >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Navigation */}
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="/index" className={activeRoute('index')}>
                <i className="ni ni-pin-3" />
                Inicio
              </NavLink>
           </NavItem>
           <NavItem>
            <NavLink href="/cliente/jugar" className={activeRoute('jugar')}>
              <i className="ni ni-check-bold" />
              Jugar Loterías
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/cliente/comprar-puntos" className={activeRoute('comprar-puntos')}>
              <i className="ni ni-bag-17" />
              Comprar Puntos
            </NavLink>
          </NavItem>

            <NavItem>
              {clienteAutenticado &&
              <NavLink href="/cliente/resultados" className={activeRoute('resultados')}>
                <i className="ni ni-notification-70" />
                Resultados de Hoy
              </NavLink>
              }
              {!clienteAutenticado &&
                <NavLink href="/resultados" className={activeRoute('resultados')}>
                <i className="ni ni-notification-70" />
                Resultados de Hoy
              </NavLink>
              }
            </NavItem>
            <NavItem>
              <NavLink href="/award-winning-combination" className={activeRoute('award-winning-combination')}>
                <i className="fa fa-gift" />
                Combinación Premiada
              </NavLink>
            </NavItem>
            {clienteAutenticado && <>
            <p className="nav-link m-0 text-light">MI CUENTA</p>
              <NavItem className={activeRoute('jugadas')}>
                <NavLink href="/cliente/jugadas">
                  Mis Pedidos
                </NavLink>
              </NavItem>
              
              <NavItem  className={activeRoute('cartera')}>
                <NavLink href="/cliente/cartera">
                  Cartera
                </NavLink>
              </NavItem>
              <NavItem className={activeRoute('perfil')}>
                <NavLink href="/cliente/perfil">
                  Editar Perfil
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={(e) => logout(e)}>
                  Salir
                </NavLink>
              </NavItem>
              </> }
          </Nav>

        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
