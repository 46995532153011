/*!

=========================================================
*  LOADING SPINNER
=========================================================
*/
const Spinner = () => {
    return ( <div className="text-center"><div className="spinner-border spinner-big" role="status"></div></div>
    );
};

export default Spinner;
