/*!
=========================================================
* VARIABLES APP CONTROL
*/
import Auth from '../middlewares/Auth';
import Cookie from 'universal-cookie';
const cookie = new Cookie();
const nombreCookieCesta = process.env.REACT_APP_CESTA_COOKIE_NAME;
const dominio = process.env.REACT_APP_DOMAIN;
const appurl = process.env.REACT_APP_URL;
/*****************************************/
const appEnv = {
    /*-- env --*/
    appurl: appurl,
    appname:process.env.REACT_APP_NAME,
    apiurl: process.env.REACT_APP_API_URL,
    apiimgpath: process.env.REACT_APP_IMAGES_URL,
    imagesurl: appurl + 'images/',
    /*-- cesta cookie --*/
    getIdCesta: () => cookie.get(nombreCookieCesta),

    
    setIdCesta: (id_cesta) => {
      const timestamp = new Date().getTime();
      const expiracion = timestamp + (60 * 60 * 24 * 1000);/** Expiración del cookies de 24h ***/
      const fechaExpiracion = new Date(expiracion);
      if (!cookie.get(nombreCookieCesta) && id_cesta){
          cookie.set(nombreCookieCesta, id_cesta, { path: '/', expires: fechaExpiracion, domain: dominio, sameSite: 'none',secure: true });
      }
    },
    removeIdCesta: () => {
        cookie.remove(nombreCookieCesta, { path: '/',domain:dominio ,sameSite: 'none',secure: true });
    },
    setCountCesta: (value) => {
      const divCountCesta = document.getElementById("countCesta");
      if (divCountCesta) divCountCesta.innerHTML = (value) ? value : '';
    },
    /*-- cliente --*/
    clienteAvatar: () =>{
      return require("../assets/img/theme/user.png")

    },
    clienteId: () =>{
      return Auth.getCid();
    },
    clienteNombre: () =>{
      return Auth.getNombre();
    },
    auth: (value) => {
      switch(value) {
        case 'token':
          return Auth.getToken();
          break;
        case 'username':
          let username = Auth.getName() || '';
          return (username) ? username.replace(/ .*/,''):'';
          break;
        case 'metodosPagosApp':
          return Auth.getMetodosPagosApp() || {};
          break;
        case 'servicioRemesaActivo':
          return Number(Auth.getServicioRemesaActivo()); //1 or 0
          break;
        case 'metodosPagosDisponibles':
          return Auth.getMetodosPagosDisponibles() || [];
          break;
        default:
          return Auth.getAuth();
      }
    },
    getToken: () =>{
      return Auth.getToken();
    },
    logout: () => { Auth.signOut(); },

};
export default appEnv;
